import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'stateHandling/hooks'
import { Button, Row, Col, Alert } from 'react-bootstrap'

import exerciseService from 'services/exercises'
import userService from 'services/users'
import { changeAppLoading, changeCreateLoading } from 'reducers/appStateSlice'
import { setEditExercise } from 'reducers/newOrEditedExerciseSlice'
import { changeExerciseLoading } from 'reducers/currentExerciseSlice'
import { setExercises, getPatientData } from 'reducers/currentPatientSlice'
import { setShowMessage, getPatientList, setPatientListLoading } from 'reducers/userSlice'
import { changePreviousPage } from 'reducers/previousPageSlice'

import ExerciseLibraryTable from 'components/ExerciseLibraryTable/ExerciseLibraryTable'
import ExerciseLibraryCardGroup from 'components/ExerciseLibraryCardGroup/ExerciseLibraryCardGroup'
import { filterStatisticsByExerciseId } from 'utils/statisticsHelper'

import { Dispatch } from 'types/Types'
import { LibraryExercise } from 'types/ExerciseTypes'
import { AssignedExercise } from 'types/UserTypes'
import { DBStatistic } from 'types/StatisticTypes'
import { ASSIGNED_EXERCISE } from 'utils/config'
import { getAssignedExerciseData} from 'utils/mixpanelHelper'

interface Props {
	consent: boolean
	mixpanel: any
	distinctExercises: LibraryExercise[]
    baseLib: boolean
	assign: boolean
	patientId: string
}

const ExercisesWrapper = ({ consent, mixpanel, distinctExercises, baseLib, assign, patientId }: Props) => {
	const { t, i18n} = useTranslation()
	const dispatch = useDispatch<Dispatch>()
	const navigate = useNavigate()
	const assignedExercises = useAppSelector(state => state.currentPatient.exercises)
	const allExercises = useAppSelector(state => state.exerciseList.exercises)
	const patient = useAppSelector(state => state.currentPatient)
	const user = useAppSelector(state => state.user.data)
	const gridView = useAppSelector(state => state.previousPage.gridView)
	const adultLibrary = useAppSelector(state => state.previousPage.adultLibrary)

	const [previousAssignedExercises, setPreviousAssignedExercises] = useState(assignedExercises)
	const [filteredPatientStatistics, setFilteredPatientStatistics] = useState<DBStatistic[]>([])
	const patientStatistics = useAppSelector(state => state.currentPatient.statistics)
	const [showStatistics, setShowStatistics] = useState<boolean>(false)

	const [selectedExercises, setSelectedExercises] = useState<LibraryExercise[]>([])
	const [numSelectedExercises, setNumSelectedExercises] = useState(0)

	/**
	* Get the patient data, if not available in redux
	*/
	useEffect(() => {
		if (assign && patient.id !== patientId) {
			if (patientId) {
				dispatch(getPatientData({slpId: user.id, patientId: patientId}))
			} else {
				navigate('/patients')
			}
		}
	}, [patientId])

	useEffect(() => {
		if (assign && patient.userStatus === 'DOES NOT EXIST') {
			changeUserMessage('danger', `${t('edit_patient.messages.load_patient_failure')}`)
			navigate('/patients')
		}
	}, [patient.userStatus])

	useEffect(() => {
		const tempExercises = allExercises.map((e) => {
			const exercise = Object.assign({}, e) as LibraryExercise // copy of object
			exercise['useWriting'] = false
			exercise['useSpeaking'] = false
			exercise['useReading'] = false
			exercise['useListening'] = false
			const assigned = assignedExercises.filter(
				(e2) => e2.exerciseId === exercise.id
			)
			if (assigned.length > 0) {
				if (assigned[0].answerFormat.includes('speak'))
					exercise.useSpeaking = true
				if (assigned[0].answerFormat.includes('write'))
					exercise.useWriting = true
				if (assigned[0].answerFormat.includes('read'))
					exercise.useReading = true
				if (assigned[0].answerFormat.includes('listen'))
					exercise.useListening = true
			}
			return exercise
		})
		setSelectedExercises(tempExercises)
	}, [allExercises, assignedExercises])

	useEffect(() => {
		let numSelected = 0
		selectedExercises.forEach((e) => {
			if (e.useSpeaking) numSelected++
			if (e.useWriting) numSelected++
			if (e.useReading) numSelected++
			if (e.useListening) numSelected++
		})
		setNumSelectedExercises(numSelected)
	}, [selectedExercises])

	/**
     * Opens the selected exercise in preview
     * @param {Number} id - Id of the selected exercise
	 * @param {String} renderType - type to be rendered
	 * @param {Boolean} baseLib - is the exercise a baselibrary or not
     * @returns {Void}
     */
	const viewExercise = (id, renderType, baseLib) => async () => {
		dispatch(changeAppLoading(true))
		const data = await exerciseService.getById(id)
		dispatch(setEditExercise(data))
		dispatch(changeAppLoading(false))
		if (assign) {
			dispatch(changePreviousPage(`/patients/${patientId}`))
		} else {
			dispatch(changePreviousPage('/edit_exercises'))
		}
		navigate(`/exercises/view/${id}/${renderType}/${baseLib}`)
	}
	/**
     * Fetches statistics data for a specific exercise and toggles showing a modal
     * @param {string} id - id of exercise
     * @returns {void}
     */
	const toggleShowStatistics = (exerciseId) => {
		const exerciseStatistics = filterStatisticsByExerciseId(exerciseId,patientStatistics)
		setFilteredPatientStatistics(exerciseStatistics)
		setShowStatistics(true)
	}

	/**
     * Handles closing the statistics modal
     * @returns {void}
     */
	const handleCloseStatisticsModal = () => {
		setShowStatistics(false)
	}

	/**
     * Start an exercise in patient view and set loading state
     * @param {string} id - id of exercise
	 * @param {string} answerFormat - answer format of exercise
     * @returns {void}
     */
	const startExerciseAsPatient = (id, answerFormat) => {
		dispatch(changeExerciseLoading(true))
		if (assign) {
			dispatch(changePreviousPage(`/patients/${patientId}`))
		} else {
			dispatch(changePreviousPage('/edit_exercises'))
		}
		if (answerFormat === 'listen' || answerFormat === 'read') {
			navigate(`/exercise/comprehension/${id}/${answerFormat}`)
		} else {
			navigate(`/exercise/production/${id}/${answerFormat}`)
		}
	}

	/**
     * Check an exercise -- add to data structure with assigned exercises
     * @param {ChangeEvent<HTMLInputElement>} event - clickevent
	 * @param {LibraryExercise} e - exercise that was selected
	 * @param {string} type - answerFormat of the chosen exercise
     * @returns {void}
     */
	const onItemCheck = (event, e, type) => {
		const tempExercises = selectedExercises.map((ex) => {
			if (ex.id === e.id) {
				if (type === 'write' && ex.type.includes('write')) {
					ex.useWriting = event.target.checked
				} else if (type === 'speak' && ex.type.includes('speak')) {
					ex.useSpeaking = event.target.checked
				} else if (type === 'read' && ex.type.includes('read')) {
					ex.useReading = event.target.checked
				} else if (type === 'listen' && ex.type.includes('listen')) {
					ex.useListening = event.target.checked
				}
			}
			return ex
		})
		setSelectedExercises(tempExercises)
	}

	const handleCancel = () => {
		navigate(`/patients/${patientId}`)
	}

	/**
	 * Put the assigned exercises data in correct data structure for saing
	 * Calling the save- function and updating redux
	 */
	const handleSave = () => {
		const assignedExercises = [] as AssignedExercise[]
		const langCode = i18n.language
		selectedExercises.forEach((e) => {
			if (e.useWriting || e.useSpeaking || e.useReading || e.useListening) {
				const answerFormats = [] as string[]
				if (e.useWriting) answerFormats.push('write')
				if (e.useSpeaking) answerFormats.push('speak')
				if (e.useReading) answerFormats.push('read')
				if (e.useListening) answerFormats.push('listen')
				assignedExercises.push({ exerciseId: e.id, answerFormat: answerFormats })

				const isNewlyAssignedExercise = !previousAssignedExercises.some((prev) => prev.exerciseId === e.id)

				if (consent && isNewlyAssignedExercise) {
					const data = getAssignedExerciseData(e, langCode)
					mixpanel.track(ASSIGNED_EXERCISE, data)
				}

			}
		})
		dispatch(setExercises(assignedExercises))
		saveUserSettings(assignedExercises)
		navigate(`/patients/${patientId}`)
	}

	/**
	 * wrapper for API call for updating the assigned exercises for the patient
	 * @param {AssignedExercise[]} assignedExercises - assigned exercises
	 */
	const saveUserSettings = async (assignedExercises) => {
		try {
			dispatch(changeCreateLoading(true))
			const data = new FormData()
			data.append('id', patientId)
			assignedExercises.forEach(e => {
				data.append('exerciseIds', e.exerciseId)
				data.append('exerciseAnswerFormats', JSON.stringify(e.answerFormat))
			})
			await userService.updateUserSettings(data)
			changeUserMessage('success', `${t('edit_patient.messages.success_exercises')}: ${patient.username}`)
			dispatch(setPatientListLoading(true))
			dispatch(getPatientList(user.id))
		} catch (err) {
			console.error('Error: ', err)
			changeUserMessage('danger', `${t('edit_patient.messages.failure_exercises')}: ${err.response.data}`)
		}
	}

	const changeUserMessage = (variant, text) => {
		dispatch(setShowMessage({
			showMessage: true,
			variant: variant,
			message: text
		}))
		setTimeout(() => {
			dispatch(setShowMessage({showMessage: false, variant: 'warning', message: ''}))
		}, 10000)
	}

	return (
		<>
			{ gridView
				? <ExerciseLibraryCardGroup
					exercises={distinctExercises}
					baseLib={baseLib}
					viewExercise={viewExercise}
					startExercise={startExerciseAsPatient}
					assign={assign}
					selectedExercises={selectedExercises}
					exerciseSelect={onItemCheck}
					showPlay={!assign}
					showStatistics={showStatistics}
					filteredPatientStatistics={filteredPatientStatistics}
					toggleShowStatistics={toggleShowStatistics}
					handleCloseStatisticsModal={handleCloseStatisticsModal}
				/>
				: <ExerciseLibraryTable
					exercises={distinctExercises}
					baseLib={baseLib}
					viewExercise={viewExercise}
					startExercise={startExerciseAsPatient}
					assign={assign}
					selectedExercises={selectedExercises}
					exerciseSelect={onItemCheck}
					showPlay={!assign}
					showStatistics={showStatistics}
					filteredPatientStatistics={filteredPatientStatistics}
					toggleShowStatistics={toggleShowStatistics}
					handleCloseStatisticsModal={handleCloseStatisticsModal}
				/>
			}
			{ assign
				? <div className="fixed-bottom exercise--library-select-footer" data-cy='exercise_assign_footer'>
					<Row xs='auto' className='mx-2 justify-content-end'>
						<Col className='align-self-center' data-cy='exercise_assign_num_selected'>
							{t('edit_patient.exercises.num_selected', {numSelectedExercises})}
						</Col>
						<Col>
							<Button
								onClick={handleCancel}
								className='exercise-library--secondary-button'
								variant='outline-secondary'
								data-cy='exercise_assign_footer_cancel_button'
							>
								{t('edit_patient.exercises.cancel')}
							</Button>
						</Col>
						<Col>
							<Button
								onClick={handleSave}
								data-cy='exercise_assign_footer_assign_button'
							>
								{t('edit_patient.exercises.save_selected')}
							</Button>
						</Col>
					</Row>
				</div>
				: <></>
			}
			{distinctExercises.length === 0 && !adultLibrary
				? <Alert variant='success'>{t('edit_exercise.library_user_category_select.empty_library_message')}</Alert>
				: <></>
			}
		</>
	)
}

export default ExercisesWrapper