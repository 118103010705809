import { useTranslation } from 'react-i18next'
import React, { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'stateHandling/hooks'
import { useParams, useNavigate } from 'react-router-dom'
import { Container, Button } from 'react-bootstrap'

import { changeAppLoading } from 'reducers/appStateSlice'
import { setEditExercise } from 'reducers/newOrEditedExerciseSlice'
import { changePreviousPage } from 'reducers/previousPageSlice'
import exerciseService from 'services/exercises'

import { INSTRUCTOR_DOWNLOAD } from 'utils/config'

import DownloadableProductionExercise from './DownloadableProductionExercise'
import DownloadableComprehensionExercise from './DownloadableComprehensionExercise'
import { getDownloadPdfData } from 'utils/mixpanelHelper'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudArrowDown, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Logo from 'dialog_logo_inverted_text_purple.png'

import { PDFViewer, PDFDownloadLink, Document, Page, View, Text, Image as PdfImage } from '@react-pdf/renderer'

const DownloadableExercise = ({ consent, mixpanel }) => {
	const { t } = useTranslation()
	const { id } = useParams()
	const { renderType } = useParams()
	const { baselib } = useParams()

	const dispatch = useDispatch()
	const navigate = useNavigate()

	const exercise = useAppSelector(state => state.newOrEditedExercise.exercise)
	const answerFormat = useAppSelector(state => state.newOrEditedExercise.exercise.answerFormat)
	const questions = useAppSelector(state => state.newOrEditedExercise.questions)
	const previousPage = useAppSelector(state => state.previousPage.path)

	useEffect(() => {
		if (id && previousPage === '/') {
			dispatch(changePreviousPage(`/exercises/view/${id}/${renderType}/${baselib}`))
		}
	}, [id])

	/**
     * Callback for fetching and dispatching action wiht exercise data
     */
	const fetchExercise = useCallback(async () => {
		if (exercise.id === '') {
			dispatch(changeAppLoading(true))
			const data = await exerciseService.getById(id)
			dispatch(setEditExercise(data))
			dispatch(changeAppLoading(false))
		}
	}, [id])

	/**
     * Call the callback for fetching and storing the exercise data
    */
	useEffect(() => {
		try {
			fetchExercise()
		} catch (exception) {
			console.error(exception)
		}
	}, [fetchExercise])

	const PDFDoc = () => (
		<Document>
		  <Page size="A4">
				{baselib === 'true'
					? <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
						<PdfImage src={Logo} style={{ width: '100', margin: '20' }}></PdfImage>
					</View>
					: <></>
				}
				<View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', border: '1px solid #000000', borderRadius: '5px', margin: '16px 5px 0 5px', padding: '5px'}} wrap={false}>
					<Text data-cy='download_page_title' style={{ fontSize: '26px' }}>{exercise.title.text}</Text>
					<Text data-cy='download_page_description_title' style={{ margin: '5px' }}>{t('preview_exercise.description.title')}</Text>
					<Text data-cy='download_page_description_content' style={{ fontSize: '14px', margin: '5px' }}>{exercise.description.patient.text}</Text>
				</View>
				{exercise.id && (
					exercise.template !== 'comprehension' ?
						<DownloadableProductionExercise questions={exercise.questions}/>
						: <DownloadableComprehensionExercise answerFormat={answerFormat} questions={questions} />
				)}
		  </Page>
		</Document>
	  )

	const handleDownloadPdfEvent = (exercise) => {
		if (consent) {
			const data = getDownloadPdfData(exercise)
			mixpanel.track(INSTRUCTOR_DOWNLOAD, data)
		}
	}

	return (
		<Container className='user-select'>
			<Button
				className='me-3'
				onClick={() => navigate(`/exercises/view/${id}/${renderType}/${baselib}`)}
				data-cy='back_to_preview_button'
			>
				<FontAwesomeIcon className='me-2' icon={faArrowLeft} />
				{t('preview_exercise.download_exercise.back')}
			</Button>
			<PDFDownloadLink document={<PDFDoc />} fileName={exercise.title.text} style={{ color: '#FFFFFF' }}>
				{({ blob, url, loading, error }) =>
					<Button
						data-cy='download_button'
						onClick={() => {
							handleDownloadPdfEvent(exercise)
						}}
					>
						<FontAwesomeIcon className='me-2' icon={faCloudArrowDown} />
						{loading
							? t('preview_exercise.download_exercise.pending_button')
							: t('preview_exercise.download_exercise.download_button')}
					</Button>
				}
			</PDFDownloadLink>
			<br />
			<PDFViewer data-cy='pdf-viewer' style={{ width: '80vw', height: '80vh', marginTop: '1rem', borderRadius: '5px' }}>
				<PDFDoc />
			</PDFViewer>
		</Container>
	)
}

export default DownloadableExercise