import React, { useState } from 'react'
import { useAppSelector } from 'stateHandling/hooks'
import { Row, Col, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import PlayAnswerChildren from 'components/PlayAnswerChildren'
import CoinsCollected from 'components/ExerciseFeedback/CoinsCollected'
import WrittenAnswer from 'components/WrittenAnswer' // we might want to add this back later
import FeedbackIncorrectChildren from 'components/ExerciseFeedback/FeedbackIncorrectChildren'
import FeedbackCorrectChildren from 'components/ExerciseFeedback/FeedbackCorrectChildren'
import AnswerModal from 'components/Answers/AnswerModal'
import { EXERCISE_ANSWER_STATUS_CORRECT, EXERCISE_TYPE_SPEAK, EXERCISE_TYPE_WRITE } from 'utils/config'

const ExerciseFeedbackChildren = () => {
	const format = useAppSelector(state => state.currentExercise.exercise.answerFormat)
	const answerStatus = useAppSelector(state => state.currentExercise.stats.answerStatus)
	const [show, setShow] = useState(false)
	const { t } = useTranslation()

	const renderFeedback = () => {
		if (format === EXERCISE_TYPE_SPEAK) {
			return (
				<>
					<Col xs={4} sm={4}>
						<PlayAnswerChildren />
						<div className='text-center'>
							<Button className='text-nowrap p-2 mt-3' variant='outline-primary' onClick={() => setShow(true)}>{t('exercise.listen_view.show_answers')}</Button>
						</div>
					</Col>
					<Col xs={8} sm={8} data-cy='feedback_image_container'>
						{answerStatus === EXERCISE_ANSWER_STATUS_CORRECT
							? <FeedbackCorrectChildren />
							: <FeedbackIncorrectChildren />
						}
						<CoinsCollected />
					</Col>
				</>
			)
		} else if (format === EXERCISE_TYPE_WRITE) {
			return (
				<>
					<Col xs={4} sm={4}>
						{answerStatus === 'correct'
							? <>
								<WrittenAnswer />
								<div className='text-center'>
									<Button className='text-nowrap p-2 mt-3' variant='outline-primary' onClick={() => setShow(true)}>{t('exercise.listen_view.show_answers')}</Button>
								</div>
							</>
							: <></>}
					</Col>
					<Col xs={8} sm={8} data-cy='feedback_image_container'>
						{answerStatus === EXERCISE_ANSWER_STATUS_CORRECT
							? <FeedbackCorrectChildren />
							: <FeedbackIncorrectChildren />
						}
						<CoinsCollected />
					</Col>
				</>
			)
		} else {
			return (
				<Col className='d-flex flex-column justify-content-center align-items-center'>
					{answerStatus === EXERCISE_ANSWER_STATUS_CORRECT
						? <FeedbackCorrectChildren />
						: <FeedbackIncorrectChildren />
					}
					<CoinsCollected />
				</Col>
			)
		}
	}

	return (
		<>
			<AnswerModal
				show={show}
				handleClose={() => setShow(false)}
			/>
			<Row className='align-items-center justify-content-center' data-cy='exercise_feedback_container'>
				{renderFeedback()}
			</Row>
		</>
	)
}

export default ExerciseFeedbackChildren