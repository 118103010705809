import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate } from 'react-router-dom'
import { userIsAdminOrSlp} from 'utils/helpers'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'stateHandling/hooks'

import {
	nextQuestion,
	changeCurrentQuestion,
	clearCurrentExercise,
	changeAudioBuffer,
	changeRecordedAudio,
	changeWrittenAnswer,
	decreaseNumTries,
	increaseNumCorrect,
	changeAnswerStatus,
	changeStatus,
	changeConfidence,
	changeDiff,
	changeRecordingDuration,
	addQuestionStat,
	startExercise,
	setExerciseStartTime,
	changeRetry,
	increaseCoins
} from 'reducers/currentExerciseSlice'
import { changeExerciseAudio } from 'reducers/exerciseListSlice'

import {
	transferAudio,
	transferAWSIds,
	listenSocket,
	disconnectSocket,
	initSocketConnection,
	transferText
} from 'services/socket'
import statisticsService from 'services/statistics'
import { getRecorder } from 'recorder/recorder'
import { parseExerciseAudio, getExerciseAudioReqObject } from 'utils/helpers'
import { getExerciseData, getExerciseStartData } from 'utils/mixpanelHelper'
import { getExerciseStatistic, getQuestionStat } from 'utils/statisticsHelper'
import { usePageVisibility } from 'hooks/usePageVisibility'
import timer from 'timer/index'
import {
	EXERCISE_LIFECYCLE,
	INSTRUCTOR_EXERCISE_LIFECYCLE,
	RECORDRTC_SAMPLE_RATE,
	RECORDRTC_AUDIO_FORMAT,
	MEDIARECORDER_AUDIO_FORMAT,
	RECORDRTC_ENCODING,
	MEDIARECORDER_ENCODING,
	ENV,
	QUESTION_AUDIO_KEYS,
	COINS_LOW,
	COINS_MID,
	COINS_HIGH,
	EXERCISE_TYPE_SPEAK,
	SKILL_PHONOLOGY
} from 'utils/config'
import ExercisePrompt from 'components/ExercisePrompt/ExercisePrompt'
import ExerciseDescription from 'components/ExerciseDescription/ExerciseDescription'
import ExerciseHeader from 'components/ExerciseHeader/ExerciseHeader'
import ExerciseHeaderDescription from 'components/ExerciseHeader/ExerciseHeaderDescription'
import StatusNone from 'components/ExerciseFooter/StatusNone'
import StatusRepeat from 'components/ExerciseFooter/StatusRepeat'
import StatusNext from 'components/ExerciseFooter/StatusNext'
import StatusDescription from 'components/ExerciseFooter/StatusDescription'
import StatusPhonology from 'components/ExerciseFooter/StatusPhonology'
import FeedbackArticulationChildren from 'components/ExerciseFeedback/FeedbackArticulationChildren'
import Finished from 'components/Finished'
import FinishedChildren from 'components/FinishedChildren'
import Loading from 'components/Loading'
import AudioProcessingConsentModal from 'components/AudioProcessingConsentModal'

import { Dispatch } from 'types/Types'
import 'pages/ExercisePage/ExercisePage.css'


const ExercisePage = ({ consent, mixpanel }) => {
	const [recorder, setRecorder] = useState<any>(null)
	const [usingRTCRecorder, setUsingRTCRecorder] = useState(false)
	const [audioStream, setAudioStream] = useState<any>(null)
	const [mimeType, setMimeType] = useState('')
	const [recorderAlert, setRecorderAlert] = useState(true)
	const [backendError, setBackendError] = useState(false)
	const [audioBits, setAudioBits] = useState(0)
	const [chunks, setChunks] = useState([])
	const [intervalId, setIntervalId] = useState<any>(null)
	const [recordingStartTime, setRecordingStartTime] = useState<any>(null)
	const [mixpanelStartEventSent, setMixpanelStartEventSent] = useState(false)
	const [showConsentPopup, setShowConsentPopup] = useState(false)

	// Helpers to avoid unwanted updates and re-rendering
	const isFirstRender = useRef(true)
	const answerCollected = useRef(false)

	const exercise = useAppSelector(state => state.currentExercise.exercise)
	const isAdult = useAppSelector(state => state.currentExercise.exercise.isAdult)
	const exerciseLength = useAppSelector(state => state.currentExercise.length)
	const currentQuestion = useAppSelector(state => state.currentExercise.currentQuestion)
	const stats = useAppSelector(state => state.currentExercise.stats)
	const exerciseLoading = useAppSelector(state => state.currentExercise.loading)
	const user = useAppSelector(state => state.user)
	const previousPagePath = useAppSelector(state => state.previousPage.path)
	const audioProcessingConsent = useAppSelector(state => state.appState.audioProcessingConsent)
	const exerciseAudio = useAppSelector(state => state.exerciseList.exerciseAudio)
	const retry = useAppSelector(state => state.currentExercise.stats.retry)
	const skill = useAppSelector(state => state.currentExercise.exercise.skill)
	const dispatch = useDispatch<Dispatch>()

	const { i18n } = useTranslation()
	const { id, format } = useParams()
	const navigate = useNavigate()
	const isPageVisible = usePageVisibility()

	/**
    * Function to initialize or destroy the RTC recorder based on page visibility
	* Page visibility (meaning if the page / PWA is in the foreground) is
	* determined using the Page Visibility API:
	* https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
    */
	useEffect(() => {
		if (!isFirstRender.current) {
			try {
				if (isPageVisible) {
					loadRtcRecorder()
				} else {
					destroyRtcRecorder()
				}
			} catch (exception) {
				console.error(exception)
			}
		}
	}, [isPageVisible])

	/**
     * Callback for recorder initialization.
     * We need to update when the chunks are updated, otherwise the state is not
     * updated properly in the handleDataAvailable callback.. 
     * When using MediaRecorder, it is necessary to re-assign recorder functions when 'chunks' are updated
     */
	const initRecorder = useCallback(async () => {
		let rec = recorder
		let usingRTC = usingRTCRecorder
		let stream = audioStream
		if (!recorder) {
			[rec, usingRTC, stream] = await getRecorder()
		}
		if (rec) {
			setUsingRTCRecorder(usingRTC)
			setAudioStream(stream)
			setRecorderAlert(false)
			if (usingRTC) {
				setRecorder(rec)
				setAudioBits(RECORDRTC_SAMPLE_RATE ? +RECORDRTC_SAMPLE_RATE : 0)
				setMimeType(RECORDRTC_AUDIO_FORMAT ? RECORDRTC_AUDIO_FORMAT: '')
			} else {
				setRecorder(Object.assign(
					rec,
					{
						ondataavailable: handleDataAvailable,
						onstop: handleStop,
					},
				))
				setAudioBits(rec.audioBitsPerSecond)
				setMimeType(rec.mimeType)
			}
		} else {
			setRecorderAlert(true)
		}
	}, [chunks])

	/**
     * Firing the callback initializing the recorder
     */
	useEffect(() => {
		try {
			initRecorder()
		} catch (exception) {
			console.error(exception)
		}
	}, [initRecorder])

	/**
	 * Dispatches redux action with start-time of new exercise
	 */
	useEffect(() => {
		if (exercise.id) {
			const date = new Date()
			dispatch(setExerciseStartTime(date.toJSON()))
		}
	}, [exercise.id])

	/**
	 * Fetch the exercise audio when the exercise is loaded
	 */
	useEffect(() => {
		if (exercise.id && !(exercise.id in exerciseAudio)) {
			getExerciseAudioData()
		}
	}, [exercise.id])

	/**
     * Fetching Audio Buffers when a new question is loaded
    */
	useEffect(() => {
		getQuestionAudioData()
	}, [currentQuestion])

	/**
     * Managing the socket connection
     */
	useEffect(() => {
		try {
			initSocketConnection()
			return () => disconnectSocket()
		} catch (exception) {
			console.error(exception)
		}
	}, [])

	/**
     * Listening on the socket, retrieving data types:
	 * textAnswerFeedback: feedback and results on written answers
	 * audioAsText: feedback and results on spoken answers
	 * audioBuffer: the generated audio for questions and answers
     */
	useEffect(() => {
		listenSocket(async (err, data) => {
			try {
				if (data.type === 'textAsAudio') {
					// For listening speech synthesization results
				} else if (data.type === 'textAnswerFeedback') {
					dispatch(changeAnswerStatus(data.correct))
					dispatch(changeStatus(data.correct))
					dispatch(changeWrittenAnswer(data.answer))
					dispatch(changeConfidence(data.score))
					dispatch(changeDiff(data.diff))
				} else if (data.type === 'audioAsText' && recorder) {
					/** 
                     * When adding streaming update this to be dynamic
                     * i.e. check for correct answer, and stop recorder if that is recieved
                    */
					if (data.correct == 'correct' && !usingRTCRecorder && recorder.state === 'recording') {
						recorder.stop()
					}
					if (!answerCollected.current) {
						dispatch(changeAnswerStatus(data.correct))
						answerCollected.current = true
						dispatch(changeStatus(data.correct))
						dispatch(changeConfidence(data.confidence))
					}
				} else if (data.type === 'audioBuffer') {
					const id = Object.keys(data.audioData)[0]
					if (!QUESTION_AUDIO_KEYS.includes(id)) { // If the audioBuffer is exercise audio data
						const exerciseAudio = parseExerciseAudio(id, data.audioData[id])
						dispatch(changeExerciseAudio(exerciseAudio))
					} else {
						dispatch(changeAudioBuffer(data.audioData))
					}
				}
			} catch (exception) {
				console.error(exception)
			}
		})
	}, [recorder])

	/**
     * Firing listenStatus function on changes in status
     */
	useEffect(() => {
		listenStatus()
	}, [stats.status])

	/**
     * Handling recorded audio if using MediaRecorder
	 * Block from running on initial render
     */
	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false
			return
		}
		handleRecordedAudio()
	}, [chunks])

	/**
	* Destroy RTC recorder on component unmount
	 */
	useEffect(() => {
		return () => {
			destroyRtcRecorder()
		}
	}, [])

	/**
     * Exercise lifecycle event
     */
	useEffect(() => {
		if (exercise.id !== '' && !mixpanelStartEventSent){
			handleExerciseLifecycle('start')
			setMixpanelStartEventSent(true)
		}
	}, [exercise])

	/**
     * Callback for fetching exercise data
     */
	const fetchExerciseData = useCallback(async () => {
		if (id && format) {
			dispatch(startExercise({id: id, answerFormat: format }))
		}
	}, [id])

	/**
	 * Call the callback for fetching and storing the exercise data
	 */
	useEffect(() => {
		fetchExerciseData()
	}, [fetchExerciseData])

	/**
     * Show the audioProcessingConsent modal if consent is not given
     */
	useEffect(() => {
		setShowConsentPopup(!audioProcessingConsent && format === 'speak')
	}, [audioProcessingConsent])

	/**
	 * Get the audio data for the question by sending the AWS ids
	 * @returns { void }
	 */
	const getQuestionAudioData = () => {
		try {
			transferAWSIds(currentQuestion.audio)
		} catch (exception) {
			console.error(exception)
		}
	}

	/**
	 * Get the audio data for the exercise by sending the AWS ids
	 * @returns { void }
	 */
	const getExerciseAudioData = () => {
		try {
			const audioObject = getExerciseAudioReqObject(exercise)
			transferAWSIds(audioObject)
		} catch (exception) {
			console.error(exception)
		}
	}

	/**
	 * Function to destroy the RTC Recorder and audioStream
	 * Sets states related to recorder to null
	 * @returns { void }
	 */
	const destroyRtcRecorder = () => {
		if (usingRTCRecorder) {
			setRecorderAlert(true)
			try {
				if (audioStream) {
					audioStream.getTracks().forEach(track => {
						track.stop()
					})
					setAudioStream(null)
				}
				if (recorder) {
					recorder.destroy()
					setRecorder(null)
				}
			} catch (exception) {
				console.error(exception)
			}
		}
	}

	/**
	 * Function to re-load the RTC Recorder on navigation from page or app
	 * Sets the states related to the recorder
	 * @returns { void }
	 */
	const loadRtcRecorder = async () => {
		if (usingRTCRecorder) {
			try {
				const [rec, usingRTC, stream] = await getRecorder()
				if (rec && usingRTC) {
					setAudioStream(stream)
					setRecorder(rec)
					setAudioBits(RECORDRTC_SAMPLE_RATE ? +RECORDRTC_SAMPLE_RATE : 0)
					setMimeType(RECORDRTC_AUDIO_FORMAT ? RECORDRTC_AUDIO_FORMAT : '')
					setRecorderAlert(false)
				} else {
					setRecorderAlert(true)
				}
			} catch (exception) {
				console.error(exception)
				setRecorderAlert(true)
			}
		}
	}

	/**
	 * Function to compile the statistics object for an exercise and post to DB
	 * @param { boolean } completed
	 * @returns { AnswerStat } Object containing the statistic for a whole exercise
	 */
	const saveStatistics = async (completed=false) => {
		try {
			const data = getExerciseStatistic(exercise, user.data.cognitoId, stats, completed)
			await statisticsService.postStatistic(data)
		} catch (exception) {
			console.error(exception)
		}
	}

	/**
	 * Triggers on an exercise being finished
	 * Calls function to update state, and routes user to exercise list
	 * @returns { void }
	 */
	const handleFinishedEvent = () => {
		handleExerciseLifecycle('complete')
		navigate(previousPagePath)
	}

	/**
     * Statistics and Mixpanel data collection
	 * If running cypress tests, data should not be collected
     * @param {String} type - type of user event, i.e. start, discontinue or complete
     */
	const handleExerciseLifecycle = type => {
		if (!(ENV === 'cypress') && !userIsAdminOrSlp(user.data)) {
			if (type === 'discontinue' || type === 'complete') {
				saveStatistics(type === 'complete')
			}
			if (consent) {
				let data = {}
				if (type === 'start') data = getExerciseStartData(exercise, i18n.language, exerciseLength)
				else data = getExerciseData(exercise, i18n.language, exerciseLength, stats)
				data['event_type'] = type
				mixpanel.track(EXERCISE_LIFECYCLE, data)
			}
		}
		else if (!(ENV === 'cypress') && userIsAdminOrSlp(user.data)){
			if (consent) {
				let data = {}
				if (type === 'start') data = getExerciseStartData(exercise, i18n.language, exerciseLength)
				else data = getExerciseData(exercise, i18n.language, exerciseLength, stats)
				data['event_type'] = type
				mixpanel.track(INSTRUCTOR_EXERCISE_LIFECYCLE, data)
			}
		}
		if (type === 'discontinue' || type === 'complete') { // exercise discontinued
			destroyRtcRecorder()
			dispatch(clearCurrentExercise())
		}
	}

	/**
     * Callback function for the recorder ondataavailable event
     * Sets the "chunks" state with the newly recieved data
	 * @returns { void }
     */
	const handleDataAvailable = ({ data }) => {
		const newChunks = [...chunks, data] as never[]
		setChunks(newChunks)
	}

	/**
     * Callback function for the recorder onstop event
     */
	const handleStop = () => {
		clearInterval(intervalId)
	}

	/**
     * Start to record audio input
     * @returns {void}
     */
	const start = () => {
		dispatch(changeAnswerStatus(''))
		answerCollected.current = false
		try {
			if (usingRTCRecorder) {
				recorder!.startRecording()
			} else {
				// Resets the parameters on new recording as it is not always re-rendering
				isFirstRender.current = true
				setChunks([])
				// If adding parameter, dataavailable will be called every <time interval> and when the recorder is stopped.
				recorder.start()
			}
			// Sets an interval that stops the recording after 15s (if it is not stopped before)
			const interval = setInterval(() => { stop() }, 15000)
			setIntervalId(interval)
			const date = new Date()
			setRecordingStartTime(date.toJSON())
		} catch (exception) {
			console.error(exception)
		}
	}

	/**
     * Stops recording audio input and processes it after that
     * Set a timeout (0.8s) before stop to make sure the recording does not cut short (is there a better solution???)
     */
	const stop = async () => {
		try {
			await new Promise(r => setTimeout(r, 800))
			const recDuration = timer.getDuration(recordingStartTime)
			dispatch(changeRecordingDuration(recDuration))
			if (usingRTCRecorder) {
				const state = await recorder.getState()
				if (state === 'recording') {
					await recorder.stopRecording()
					clearInterval(intervalId)
					handleRecordedAudio()
				}
			} else {
				if (recorder.state === 'recording') {
					recorder.stop()
				}
			}
		} catch (exception) {
			console.error(exception)
		}
	}

	/**
     * Sends the written answer to backend using socket after it is submitted
	 * @param { string } answer - string containing the written answer
	 * @returns { void }
     */
	const handleWrittenAnswer = async (answer) => {
		try {
			const files = {
				writtenAnswer: answer,
				id: currentQuestion.id,
				answers: currentQuestion.answers.map(answerObj => answerObj.answer.text),
				langCode: currentQuestion.langCode,
				type: exercise.type
			}
			transferText(files)
		} catch (exception) {
			console.error(exception)
		}
	}

	/**
     * Format the recorded audio segments stored in the chunks state
     * and send the formatted audio through the socket
     * @returns { void }
     */
	const handleRecordedAudio = async () => {
		try {
			const files = {
				audio: {
					encoding: usingRTCRecorder ? RECORDRTC_ENCODING : MEDIARECORDER_ENCODING,
					audioBits: audioBits,
					type: '',
					blob: {} as Blob
				},
				id: currentQuestion.id,
				answers: currentQuestion.answers.map(answerObj => answerObj.answer.text),
				langCode: currentQuestion.langCode,
				type: exercise.type
			}
			if (usingRTCRecorder) {
				const blob = await recorder.getBlob()
				recorder.reset()
				files.audio.type = blob.type
				files.audio.blob = blob
			} else if (stats.answerStatus !== 'correct' && chunks.length > 0) {
				let currentMimeType = MEDIARECORDER_AUDIO_FORMAT ? MEDIARECORDER_AUDIO_FORMAT : ''
				if (mimeType === '' && recorder && recorder.mimeType) {
					currentMimeType = recorder.mimeType
				}
				files.audio.type = currentMimeType
				const blob = new Blob(chunks, { type: currentMimeType })
				files.audio.blob = blob
			}
			const audioUrl = URL.createObjectURL(files.audio.blob)
			dispatch(changeRecordedAudio(audioUrl))
			if (skill.includes(SKILL_PHONOLOGY) && format === EXERCISE_TYPE_SPEAK) dispatch(changeStatus('phonology'))
			else transferAudio(files)
		} catch (exception) {
			console.error(exception)
		}
	}

	/**
     * When value of status hook changes update related states accordingly
	 * @returns { void }
     */
	const listenStatus = () => {
		if (stats.status === 'correct') {
			dispatch(increaseNumCorrect())
			const questionStat = getQuestionStat(stats, currentQuestion.id)
			dispatch(addQuestionStat(questionStat))
			dispatch(changeStatus('next'))
			if (retry) dispatch(increaseCoins(COINS_HIGH))
			else dispatch(increaseCoins(COINS_MID))
		} else if (stats.status === 'repeat' && stats.tries <= 1) {
			dispatch(decreaseNumTries())
			dispatch(changeStatus('incorrect'))
		} else if (stats.status === 'repeat') {
			dispatch(decreaseNumTries())
		} else if (stats.status === 'incorrect') {
			if (isAdult || !retry) {
				const questionStat = getQuestionStat(stats, currentQuestion.id, false)
				dispatch(addQuestionStat(questionStat))
				dispatch(changeStatus('next'))
				dispatch(increaseCoins(COINS_LOW))
			} else if (retry) {
				dispatch(changeStatus('retry'))
			}
		} else if (stats.status === 'error') {
			dispatch(changeStatus('none'))
			setBackendError(true)
			setTimeout(() => {
				setBackendError(false)
			}, 10000)
		}
	}

	/**
     * When going to next question update the exercise data states
	 * @returns { void }
     */
	const handleQuestionState = () => {
		if (stats.index + 1 >= exerciseLength) {
			dispatch(changeStatus('finished'))
			return
		}
		const currentIndex = stats.index
		dispatch(changeConfidence(0))
		dispatch(changeCurrentQuestion(currentIndex + 1))
		dispatch(nextQuestion())
	}
	/**
	 * When skipping a question, still save the statistics (as incorrect)
	 * @returns { void }
	 */
	const handleSkip = () => {
		const questionStat = getQuestionStat(stats, currentQuestion.id, false)
		dispatch(addQuestionStat(questionStat))
		handleQuestionState()
	}

	/**
     * Event handler for 'Retry' button
     * Based of the status takes care of required updates in the app state
     * @returns { void }
     */
	const handleRetry = () => {
		dispatch(changeRetry())
		dispatch(changeStatus('none'))
	}

	/**
     * Event handler for 'Next question' buttons 
     * Based of the status takes care of required updates in the app state
     * @returns { void }
     */
	const handleQuestion = () => {
		if (stats.status === 'next' || stats.status === 'retry') {
			if (stats.index + 1 >= exerciseLength) {
				handleQuestionState()
				return
			}
			handleQuestionState()
			dispatch(changeStatus('none'))
		} else if (stats.status === 'repeat') {
			dispatch(changeStatus('none'))
		} else if (stats.status === 'incorrect') {
			dispatch(changeStatus('next'))
		}
	}

	const handleArticulationQuestion = (correct) => {
		if (correct) {
			dispatch(changeStatus('correct'))
			dispatch(changeAnswerStatus('correct'))
		} else {
			dispatch(changeStatus('incorrect'))
			dispatch(changeAnswerStatus('incorrect'))
		}
	}

	/**
     * Conditional rendering of the exercise footer
	 * @returns { Component }
     */
	const exerciseFooter = () => {
		if (stats.status === 'none') {
			return <StatusNone
				start={start}
				stop={stop}
				submit={handleWrittenAnswer}
				recorderAlert={recorderAlert}
				backendError={backendError}
			/>
		} else if (stats.status === 'repeat') {
			return (
				<>
					<StatusRepeat
						handleQuestion={handleQuestion}
						tries={stats.tries}
					/>
				</>
			)
		} else if (stats.status === 'next' || stats.status === 'retry') {
			return <StatusNext
				handleQuestion={handleQuestion}
				handleRetry={handleRetry}
				retry={retry}
			/>
		} else if (stats.status === 'description') {
			return <StatusDescription />
		} else if (stats.status === 'phonology') {
			return <StatusPhonology
				handleQuestion={handleArticulationQuestion}
				handleRetry={handleRetry}
				retry={retry}
			/>
		}
	}

	/**
     * Conditional rendering of the exercise page
	 * @returns { Component }
     */
	const renderPage = () => {
		if (stats.status === 'finished') {
			return isAdult
				? <Finished handleFinished={handleFinishedEvent} />
				: <FinishedChildren handleFinished={handleFinishedEvent} />

		} else if (stats.status === 'description') {
			return (
				<>
					<ExerciseHeaderDescription
						handleExerciseLifecycle={handleExerciseLifecycle}
					/>
					<ExerciseDescription />
					{exerciseFooter()}
				</>
			)
		} else if (stats.status === 'phonology') {
			return (
				<>
					<ExerciseHeader
						handleSkip={handleSkip}
						handleExerciseLifecycle={handleExerciseLifecycle}
					/>
					<FeedbackArticulationChildren />
					{exerciseFooter()}
				</>
			)
		} else {
			return (
				<>
					<ExerciseHeader
						handleSkip={handleSkip}
						handleExerciseLifecycle={handleExerciseLifecycle}
					/>
					<ExercisePrompt />
					{exerciseFooter()}
				</>
			)
		}
	}

	return (
		<>
			<Container fluid className='exercise--container h-100 min-vh-100'>
				{exerciseLoading
					? <Loading />
					: renderPage()
				}
				{ showConsentPopup
					? <AudioProcessingConsentModal />
					: <></>
				}
			</Container>
		</>
	)
}

export default ExercisePage
