// NOTE: this has to match localhost locally and https://dialog-web-v1.herokuapp.com/ in Heroku, otherwise socket fails
// it's contained in the build, so it cannot be managed through Heroku
// i.e. we must update .env manually

export let ENV = process.env.REACT_APP_MANUAL_ENV

export let SOCKET_ENDPOINT = process.env.NODE_ENV === 'production'
	? process.env.REACT_APP_SOCKET_ENDPOINT_PROD
	: process.env.REACT_APP_SOCKET_ENDPOINT_DEV

export let N_QUESTIONS = process.env.NODE_ENV === 'production'
	? process.env.REACT_APP_N_QUESTIONS_PROD
	: process.env.REACT_APP_N_QUESTIONS_DEV

export let EXERCISE_LIFECYCLE = process.env.NODE_ENV === 'production'
	? process.env.REACT_APP_MIXPANEL_EXERCISE_LIFECYCLE_PROD
	: process.env.REACT_APP_MIXPANEL_EXERCISE_LIFECYCLE_DEV

export let INSTRUCTOR_EXERCISE_LIFECYCLE = process.env.NODE_ENV === 'production'
	? process.env.REACT_APP_MIXPANEL_INSTRUCTOR_EXERCISE_LIFECYCLE_PROD
	: process.env.REACT_APP_MIXPANEL_INSTRUCTOR_EXERCISE_LIFECYCLE_DEV

export let INSTRUCTOR_DOWNLOAD = process.env.NODE_ENV === 'production'
	? process.env.REACT_APP_MIXPANEL_INSTRUCTOR_DOWNLOAD_PROD
	: process.env.REACT_APP_MIXPANEL_INSTRUCTOR_DOWNLOAD_DEV

export let ADDED_IMAGE = process.env.NODE_ENV === 'production'
	? process.env.REACT_APP_MIXPANEL_ADDED_IMAGE_PROD
	: process.env.REACT_APP_MIXPANEL_ADDED_IMAGE_DEV

export let ASSIGNED_EXERCISE = process.env.NODE_ENV === 'production'
	? process.env.REACT_APP_MIXPANEL_ASSIGNED_EXERCISE_PROD
	: process.env.REACT_APP_MIXPANEL_ASSIGNED_EXERCISE_DEV

export let PRACTICE_USER_CREATED = process.env.NODE_ENV === 'production'
	? process.env.REACT_APP_PRACTICE_USER_CREATED_PROD
	: process.env.REACT_APP_PRACTICE_USER_CREATED_DEV


export let INSTRUCTOR_ACTIVE = process.env.NODE_ENV === 'production'
	? process.env.REACT_APP_MIXPANEL_INSTRUCTOR_ACTIVE_PROD
	: process.env.REACT_APP_MIXPANEL_INSTRUCTOR_ACTIVE_DEV

export const AWS_URL = process.env.NODE_ENV === 'production'
	? process.env.REACT_APP_AWS_IMAGE_BASE_URL
	: process.env.REACT_APP_AWS_IMAGE_BASE_URL_DEV

export let CHATBOT_BASEURL = process.env.NODE_ENV === 'production'
	? process.env.REACT_APP_CHATBOT_BASEURL_PROD
	: process.env.REACT_APP_CHATBOT_BASEURL_DEV

export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN

// Constants for exercise type classification
export const EXERCISE_TYPE_SPEAK = 'speak'
export const EXERCISE_TYPE_WRITE = 'write'
export const EXERCISE_TYPE_READ = 'read'
export const EXERCISE_TYPE_LISTEN = 'listen'

export const EXERCISE_TYPES = [
	EXERCISE_TYPE_SPEAK,
	EXERCISE_TYPE_WRITE,
	EXERCISE_TYPE_READ,
	EXERCISE_TYPE_LISTEN
]

export const EXERCISE_TYPES_PRODUCTION = [
	EXERCISE_TYPE_SPEAK,
	EXERCISE_TYPE_WRITE,
]
export const EXERCISE_TYPES_UNDERSTANDING = [
	EXERCISE_TYPE_READ,
	EXERCISE_TYPE_LISTEN
]

export const SKILL_PHONOLOGY = 'phonology'
export const SKILL_GRAMMAR = 'grammar'
export const SKILL_UNDERSTANDING = 'understanding'
export const SKILL_VOCABULARY = 'vocabulary'
export const SKILL_PRAGMATICS = 'pragmatics'

export const EXERCISE_SKILLS = [
	SKILL_PHONOLOGY,
	SKILL_GRAMMAR,
	SKILL_UNDERSTANDING,
	SKILL_VOCABULARY,
	SKILL_PRAGMATICS
]

export const EXERCISE_SUBTYPE_VOCAB = 'vocabulary'
export const EXERCISE_SUBTYPE_REPEAT = 'repetition'
export const EXERCISE_SUBTYPE_AUTO = 'automatic'
export const EXERCISE_SUBTYPE_GRAMMAR = 'grammar'
export const EXERCISE_SUBTYPE_OTHER = 'other'
export const EXERCISE_SUBTYPE_VERBS = 'verbs'
export const EXERCISE_SUBTYPE_NOUNS = 'nouns'
export const EXERCISE_SUBTYPE_MATCHING = 'matching'
export const EXERCISE_SUBTYPE_SEMANTICS = 'semantics'
export const EXERCISE_SUBTYPE_CONTROL = 'control'
export const EXERCISE_SUBTYPE_CATEGORIZATION = 'categorization'
export const EXERCISE_SUBTYPE_PUZZLES = 'puzzles'
export const EXERCISE_SUBTYPE_FOOD = 'food_drink'
export const EXERCISE_SUBTYPE_NATURE = 'nature'
export const EXERCISE_SUBTYPE_ENTERTAINMENT = 'entertainment'

export const EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_1 = 'phonological_awareness'
export const EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_2 = 'language_awareness'

export const EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_3 = 'b_sound'
export const EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_4 = 'd_sound'
export const EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_5 = 'f_sound'
export const EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_6 = 'g_sound'
export const EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_7 = 'k_sound'
export const EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_8 = 'l_sound'
export const EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_9 = 'm_sound'
export const EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_10 = 'n_sound'
export const EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_11 = 'p_sound'
export const EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_12 = 'r_sound'
export const EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_13 = 's_sound'
export const EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_14 = 't_sound'
export const EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_15 = 'v_sound'
export const EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_16 = 'sje_sound'
export const EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_17 = 'tje_sound'
export const EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_18 = 'beginning'
export const EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_19 = 'middle'
export const EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_20 = 'end'
export const EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_21 = 'combinations'

export const EXERCISE_SUBTYPE_CHILDREN_GRAMMAR_1 = 'genitive'
export const EXERCISE_SUBTYPE_CHILDREN_GRAMMAR_2 = 'plural'
export const EXERCISE_SUBTYPE_CHILDREN_GRAMMAR_3 = 'comparative'
export const EXERCISE_SUBTYPE_CHILDREN_GRAMMAR_4 = 'syntax'
export const EXERCISE_SUBTYPE_CHILDREN_GRAMMAR_5 = 'negations'
export const EXERCISE_SUBTYPE_CHILDREN_GRAMMAR_6 = 'prepositions'
export const EXERCISE_SUBTYPE_CHILDREN_GRAMMAR_7 = 'pronouns'
export const EXERCISE_SUBTYPE_CHILDREN_GRAMMAR_8 = 'tempus'

export const EXERCISE_SUBTYPE_CHILDREN_UNDERSTANDING_1 = 'placeholder'

export const EXERCISE_SUBTYPE_CHILDREN_VOCABULARY_1 = 'categories'
export const EXERCISE_SUBTYPE_CHILDREN_VOCABULARY_2 = 'emotions'
export const EXERCISE_SUBTYPE_CHILDREN_VOCABULARY_3 = 'questions'
export const EXERCISE_SUBTYPE_CHILDREN_VOCABULARY_4 = 'antonyms'
export const EXERCISE_SUBTYPE_CHILDREN_VOCABULARY_5 = 'synonyms'

export const EXERCISE_SUBTYPE_CHILDREN_PRAGMATICS_1 = 'turn_taking'
export const EXERCISE_SUBTYPE_CHILDREN_PRAGMATICS_2 = 'storytelling'
export const EXERCISE_SUBTYPE_CHILDREN_PRAGMATICS_3 = 'interaction'
export const EXERCISE_SUBTYPE_CHILDREN_PRAGMATICS_4 = 'social_problem_solving'


export const EXERCISE_SUBTYPES_CHILDREN_PHONOLOGY_TOP = [
	EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_1,
	EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_2
]

export const EXERCISE_SUBTYPES_CHILDREN_PHONOLOGY_MIDDLE = [
	EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_3,
	EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_4,
	EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_5,
	EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_6,
	EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_7,
	EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_8,
	EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_9,
	EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_10,
	EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_11,
	EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_12,
	EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_13,
	EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_14,
	EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_15,
	EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_16,
	EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_17,
]

export const EXERCISE_SUBTYPES_CHILDREN_PHONOLOGY_BOTTOM = [
	EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_18,
	EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_19,
	EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_20,
	EXERCISE_SUBTYPE_CHILDREN_PHONOLOGY_21
]

export const EXERCISE_SUBTYPES_CHILDREN_PHONOLOGY = [
	EXERCISE_SUBTYPES_CHILDREN_PHONOLOGY_TOP,
	EXERCISE_SUBTYPES_CHILDREN_PHONOLOGY_MIDDLE,
	EXERCISE_SUBTYPES_CHILDREN_PHONOLOGY_BOTTOM
]
export const EXERCISE_SUBTYPES_CHILDREN_GRAMMAR = [
	EXERCISE_SUBTYPE_CHILDREN_GRAMMAR_1,
	EXERCISE_SUBTYPE_CHILDREN_GRAMMAR_2,
	EXERCISE_SUBTYPE_CHILDREN_GRAMMAR_3,
	EXERCISE_SUBTYPE_CHILDREN_GRAMMAR_4,
	EXERCISE_SUBTYPE_CHILDREN_GRAMMAR_5,
	EXERCISE_SUBTYPE_CHILDREN_GRAMMAR_6,
	EXERCISE_SUBTYPE_CHILDREN_GRAMMAR_7,
	EXERCISE_SUBTYPE_CHILDREN_GRAMMAR_8
]
export const EXERCISE_SUBTYPES_CHILDREN_UNDERSTANDING = []

export const EXERCISE_SUBTYPES_CHILDREN_VOCABULARY = [
	EXERCISE_SUBTYPE_CHILDREN_VOCABULARY_1,
	EXERCISE_SUBTYPE_CHILDREN_VOCABULARY_2,
	EXERCISE_SUBTYPE_CHILDREN_VOCABULARY_3,
	EXERCISE_SUBTYPE_CHILDREN_VOCABULARY_4,
	EXERCISE_SUBTYPE_CHILDREN_VOCABULARY_5
]

export const EXERCISE_SUBTYPES_CHILDREN_PRAGMATICS = [
	EXERCISE_SUBTYPE_CHILDREN_PRAGMATICS_1,
	EXERCISE_SUBTYPE_CHILDREN_PRAGMATICS_2,
	EXERCISE_SUBTYPE_CHILDREN_PRAGMATICS_3,
	EXERCISE_SUBTYPE_CHILDREN_PRAGMATICS_4,
]


export const EXERCISE_ANSWERFORMAT_DEFAULT = 'default'
export const EXERCISE_ANSWERFORMAT_FILL = 'fill'
export const EXERCISE_ANSWERFORMAT_TEXT = 'text'
export const EXERCISE_ANSWERFORMAT_IMAGE = 'image'
export const EXERCISE_ANSWERFORMAT_YESNO= 'yes/no'

export const EXERCISE_ANSWERFORMATS = [
	EXERCISE_ANSWERFORMAT_DEFAULT,
	EXERCISE_ANSWERFORMAT_FILL,
	EXERCISE_ANSWERFORMAT_TEXT,
	EXERCISE_ANSWERFORMAT_IMAGE,
	EXERCISE_ANSWERFORMAT_YESNO
]

export const EXERCISE_ANSWERFORMAT_COMPREHENSION = [
	EXERCISE_ANSWERFORMAT_IMAGE,
	EXERCISE_ANSWERFORMAT_TEXT,
	EXERCISE_ANSWERFORMAT_YESNO
]

export const EXERCISE_SUBTYPES = [
	EXERCISE_SUBTYPE_VOCAB,
	EXERCISE_SUBTYPE_REPEAT,
	EXERCISE_SUBTYPE_AUTO,
	EXERCISE_SUBTYPE_GRAMMAR,
	EXERCISE_SUBTYPE_OTHER,
	EXERCISE_SUBTYPE_VERBS,
	EXERCISE_SUBTYPE_NOUNS,
	EXERCISE_SUBTYPE_MATCHING,
	EXERCISE_SUBTYPE_SEMANTICS,
	EXERCISE_SUBTYPE_CONTROL,
	EXERCISE_SUBTYPE_CATEGORIZATION,
	EXERCISE_SUBTYPE_PUZZLES,
	EXERCISE_SUBTYPE_FOOD,
	EXERCISE_SUBTYPE_NATURE,
	EXERCISE_SUBTYPE_ENTERTAINMENT
]

export const EXERCISE_SUBTYPES_WRITE = [
	EXERCISE_SUBTYPE_VOCAB,
	EXERCISE_SUBTYPE_REPEAT,
	EXERCISE_SUBTYPE_GRAMMAR,
	EXERCISE_SUBTYPE_OTHER
]

export const EXERCISE_SUBTYPES_LISTEN_READ = [
	EXERCISE_SUBTYPE_OTHER,
	EXERCISE_SUBTYPE_VERBS,
	EXERCISE_SUBTYPE_NOUNS,
	EXERCISE_SUBTYPE_MATCHING,
	EXERCISE_SUBTYPE_SEMANTICS,
	EXERCISE_SUBTYPE_CONTROL,
	EXERCISE_SUBTYPE_CATEGORIZATION,
	EXERCISE_SUBTYPE_FOOD,
	EXERCISE_SUBTYPE_NATURE,
	EXERCISE_SUBTYPE_ENTERTAINMENT
]

export const EXERCISE_CATEGORY_LETTER = 'letter'
export const EXERCISE_CATEGORY_WORD = 'word'
export const EXERCISE_CATEGORY_SENTENCE = 'sentence'
export const EXERCISE_CATEGORY_TEXT = 'text'
export const EXERCISE_CATEGORIES = [
	EXERCISE_CATEGORY_LETTER,
	EXERCISE_CATEGORY_WORD,
	EXERCISE_CATEGORY_SENTENCE,
	EXERCISE_CATEGORY_TEXT
]

export const EXERCISE_TEMPLATE_DEFAULT = 'default'
export const EXERCISE_TEMPLATE_FILL = 'fill'
export const EXERCISE_TEMPLATE_LONG = 'long'
export const EXERCISE_TEMPLATE_COMPREHENSION = 'comprehension' // to be removed
export const EXERCISE_TEMPLATES = [
	EXERCISE_TEMPLATE_DEFAULT,
	EXERCISE_TEMPLATE_FILL,
	// EXERCISE_TEMPLATE_LONG, -- TODO: add when template exists
	EXERCISE_TEMPLATE_COMPREHENSION // to be removed
]

export const EXERCISE_CONTENT_FORMAT_DEFAULT = 'default'
export const EXERCISE_CONTENT_FORMAT_FILL = 'fill'
export const EXERCISE_CONTENT_FORMAT_LONG = 'long'
export const EXERCISE_CONTENT_FORMATS = [
	EXERCISE_CONTENT_FORMAT_DEFAULT,
	EXERCISE_CONTENT_FORMAT_FILL,
	EXERCISE_CONTENT_FORMAT_LONG
]

export const QUESTION_AUDIO_KEY_QUESTION = 'question'
export const QUESTION_AUDIO_KEY_ANSWERS = 'answers'
export const QUESTION_AUDIO_KEY_FULLSENTENCE = 'fullSentence'
export const QUESTION_AUDIO_KEY_SENTENCE = 'sentence'
export const QUESTION_AUDIO_KEY_HINT = 'hint'
export const QUESTION_AUDIO_KEYS = [
	QUESTION_AUDIO_KEY_QUESTION,
	QUESTION_AUDIO_KEY_ANSWERS,
	QUESTION_AUDIO_KEY_FULLSENTENCE,
	QUESTION_AUDIO_KEY_SENTENCE,
	QUESTION_AUDIO_KEY_HINT
]

export const EXERCISE_PROGRESS_STATUS_NEXT = 'next'
export const EXERCISE_PROGRESS_STATUS_ARTICULATION = 'phonology'
export const EXERCISE_PROGRESS_STATUS_NONE = 'none'
export const EXERCISE_PROGRESS_STATUS_DESCRIPTION = 'description'
export const EXERCISE_PROGRESS_STATUS_RETRY = 'retry'
export const EXERCISE_ANSWER_STATUS_CORRECT = 'correct'
export const EXERCISE_ANSWER_STATUS_INCORRECT = 'incorrect'

export const COINS_LOW = 10
export const COINS_MID = 20
export const COINS_HIGH = 40

// Settings for audio recording
export const RECORDRTC_ENCODING = process.env.REACT_APP_RECORDRTC_ENCODING
export const MEDIARECORDER_ENCODING = process.env.REACT_APP_MEDIARECORDER_ENCODING
export const RECORDRTC_SAMPLE_RATE = process.env.REACT_APP_RECORDRTC_SAMPLE_RATE
export const MEDIARECORDER_SAMPLE_RATE = process.env.REACT_APP_MEDIARECORDER_SAMPLE_RATE
export const RECORDRTC_AUDIO_FORMAT = process.env.REACT_APP_RECORDRTC_AUDIO_FORM
export const MEDIARECORDER_AUDIO_FORMAT = process.env.REACT_APP_MEDIARECORDER_AUDIO_FORMAT

// Setting feature toggle
export const FEATURE_USER_STATISTICS = process.env.REACT_APP_FEATURE_USER_STATISTICS

// AWS Cognito
export const AWS_COGNITO_ADMIN_USERPOOL_ID = process.env.NODE_ENV === 'production'
	? process.env.REACT_APP_AWS_COGNITO_ADMIN_USERPOOL_ID_PROD
	: process.env.REACT_APP_AWS_COGNITO_ADMIN_USERPOOL_ID_DEV

export const AWS_COGNITO_ADMIN_CLIENT_ID = process.env.NODE_ENV === 'production'
	? process.env.REACT_APP_AWS_COGNITO_ADMIN_CLIENT_ID_PROD
	: process.env.REACT_APP_AWS_COGNITO_ADMIN_CLIENT_ID_DEV
export const AWS_COGNITO_PATIENT_USERPOOL_ID = process.env.NODE_ENV === 'production'
	? process.env.REACT_APP_AWS_COGNITO_PATIENT_USERPOOL_ID_PROD
	: process.env.REACT_APP_AWS_COGNITO_PATIENT_USERPOOL_ID_DEV
export const AWS_COGNITO_PATIENT_CLIENT_ID = process.env.NODE_ENV === 'production'
	? process.env.REACT_APP_AWS_COGNITO_PATIENT_CLIENT_ID_PROD
	: process.env.REACT_APP_AWS_COGNITO_PATIENT_CLIENT_ID_DEV
