import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { Container, Image, Button, Row, Col } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, faThumbsUp, faThumbsDown, faMicrophone, faKeyboard, faVolumeHigh, faBook, faT } from '@fortawesome/free-solid-svg-icons'
import { AWS_URL } from 'utils/config'
import questionService from 'services/questions'
import { DBDefaultContentQuestion, DBFillContentQuestion, DBLongContentQuestion } from 'types/QuestionTypes'

interface Props {
    questionIds: DBDefaultContentQuestion[] | DBFillContentQuestion[] | DBLongContentQuestion[],
    contentFormat:string | undefined
    answerFormat: string | undefined
    type:string
    hasImage:boolean
}

const PreviewModal = ({ questionIds, contentFormat, answerFormat, type, hasImage } : Props ) => {
	const { t } = useTranslation()
	const [questions, setQuestions] = useState<any>()
	const [loading, setLoading] = useState<boolean>(false)


	/**
     * Fetching and storing the question data
    */
	useEffect(() => {
		const fetchData = async () => {
			const ids = questionIds.slice(0, 6)
			const data = await questionService.getByIds(ids)
			setQuestions(data)
		}

		fetchData()
	}, [])

	const getFullVocabulary = async () => {
		setLoading(true)
		const data = await questionService.getByIds(questionIds)
		setQuestions(data)
		setLoading(false)
	}

	const Icon = () => (
		<>
			{type === 'speak' || type === 'write'
				? <>
					<FontAwesomeIcon className='ms-1' icon={faVolumeHigh} />
					<FontAwesomeIcon className='mx-1' icon={faBook} />
				</>
				: type === 'read'
					? <FontAwesomeIcon className='mx-1' icon={faBook} />
					: <FontAwesomeIcon className='mx-1' icon={faVolumeHigh} />
			}
		</>
	)

	const AlternativesImage = () => <>{[1, 2, 3, 4].map(i => <FontAwesomeIcon className='mx-1' icon={faImage} />)}</>
	const AlternativesText = () => <>{[1, 2, 3, 4].map(i => <FontAwesomeIcon className='mx-1 me-1' icon={faT} />)}</>

	const AlternativesYesNo = () => (
		<>
			<FontAwesomeIcon className='me-1' style={{ color: 'green' }} icon={faThumbsUp} />
			<FontAwesomeIcon className='ms-1' style={{ color: 'red' }} icon={faThumbsDown} />
		</>
	)

	const Header = () => <div className='exercise--header-bg p-2'></div>

	const Footer = () => (
		<div className='exercise--header-bg p-2 text-center'>
			{type === 'speak'
				? <FontAwesomeIcon icon={faMicrophone} />
				: type ==='write'
					? <FontAwesomeIcon icon={faKeyboard} />
					: answerFormat === 'image'
						? <AlternativesImage />
						: answerFormat === 'text'
							? <AlternativesText />
							: <AlternativesYesNo />
			}
		</div>
	)

	const defaultExerciseFormat = () => (
		<Row className='p-2'>
			{questions[0].question.text !== ''
				? <Col className='d-flex justify-content-center align-items-center'>
					<strong>
						{t('exercise_library_card.preview_exercise_popover.question')}
					</strong>
					<Icon />
				</Col>
				: <></>
			}
			{hasImage
				? <Col className='text-center'>
					<FontAwesomeIcon style={{ fontSize: '2rem' }} icon={faImage} />
				</Col>
				: <></>
			}
		</Row>
	)

	const fillExerciseFormat = () => (
		<Row className='p-2'>
			<Col className='d-flex justify-content-center align-items-center'>
				<strong style={{ whiteSpace: 'nowrap' }}>
					{t('exercise_library_card.preview_exercise_popover.fill')}
				</strong>
				<Icon />
			</Col>
			{hasImage
				? <Col className='text-center'>
					<FontAwesomeIcon style={{ fontSize: '2rem' }} icon={faImage} />
				</Col>
				: <></>
			}
		</Row>
	)

	const productionAnswers = (q, i) => <div data-cy='preview_popup_vocabulary_item'>{i + 1}. {q.answers[0].answer.text}</div>

	const comprehensionAnswers = (q, i) => (
		answerFormat === 'text'
			? <div data-cy='preview_popup_vocabulary_item'>{i + 1}. {q.answers[0].answer.text}</div>
			: answerFormat === 'image'
				? <Image
					src={AWS_URL + q.answers[0].answer.text}
					alt='Alternative Image'
					style={{ maxHeight: '4rem', margin: '0.2rem' }}
				/>
				: <></>
	)

	return (
		<Container data-cy='preview_popup_container'>
			{questions
				? <div>
					<div className='d-flex justify-content-center' data-cy='preview_popup_exercise_format'>
						<div style={{ border: '1px solid rgba(158, 119, 237, 0.1)', borderRadius: '5px', width: '10rem' }}>
							<div>
								<Header />
								{contentFormat === 'default'
									? defaultExerciseFormat()
									: fillExerciseFormat()
								}
								<Footer />
							</div>
						</div>
					</div>
					<br />
					<div>
						{answerFormat !== 'yes/no'
							? <>
								<div>
									<strong data-cy='preview_popup_vocabulary_title'>
										{t('exercise_library_card.preview_exercise_popover.vocabulary')}:
									</strong>
								</div>
								<div style={{ maxHeight: '15rem', overflow: 'scroll' }}>
									{questions.map((q, i) => {
										return answerFormat === 'default' || answerFormat === 'fill'
											? productionAnswers(q, i)
											: comprehensionAnswers(q, i)
									})}
									{loading
										? <Spinner className='exercisePage--loading-icon mt-1' animation="border" variant="primary" />
										: <></>}
								</div>
								{questionIds.length !== questions.length && !loading
									? <Button size='sm' className='mt-2' data-cy='preview_popup_vocabulary_button' onClick={getFullVocabulary}>
										{t('exercise_library_card.preview_exercise_popover.show_all')}
									</Button>
									: <></>
								}

							</>
							: <></>}
					</div>
				</div>
				: <Spinner className='exercisePage--loading-icon' animation="border" variant="primary" />
			}
		</Container>
	)
}

export default PreviewModal