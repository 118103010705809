import React from 'react'
import FadeInImage from 'components/ExerciseFeedback/FadeInImage'
import IncorrectImage from 'images/avatars/dialo_incorrect.png'


const FeedbackIncorrectChildren = () => {
	return (
		<div className='exercise--animation-container' data-cy='feedback_incorrect_image'>
			<FadeInImage
				image={IncorrectImage}
				success={false}
			/>
		</div>
	)
}

export default FeedbackIncorrectChildren