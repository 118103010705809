import { useTranslation } from 'react-i18next'
import React, { useEffect, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'stateHandling/hooks'
import { useParams, useNavigate } from 'react-router-dom'
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap'

import { changeAppLoading } from 'reducers/appStateSlice'
import { setEditExercise, updateAudio, reset } from 'reducers/newOrEditedExerciseSlice'
import { addUserExerciseId } from 'reducers/userSlice'
import { changePreviousPage } from 'reducers/previousPageSlice'
import exerciseService from 'services/exercises'
import QuestionsTable from 'components/QuestionsTable'
import ComprehensionQuestionsTable from 'components/ComprehensionQuestionsTable'
import ConfirmDeleteExerciseModal from 'components/ConfirmDeleteExerciseModal'
import ExercisePreviewTable from 'components/ExercisePreviewTable'
import Loading from 'components/Loading'
import { EXERCISE_TEMPLATE_FILL } from 'utils/config'

import { Dispatch } from 'types/Types'
import { LibraryExercise } from 'types/ExerciseTypes'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudArrowDown, faEdit, faTrashCan, faCopy } from '@fortawesome/free-solid-svg-icons'

const ExercisePreviewPage = () => {
	const { t } = useTranslation()
	const { id } = useParams()
	const { renderType } = useParams()
	const { baselib } = useParams()
	const [showDeleteExerciseModal, setShowDeleteExerciseModal] = useState(false)
	const [showCopyExerciseModal, setShowCopyExerciseModal] = useState(false)

	const dispatch = useDispatch<Dispatch>()
	const navigate = useNavigate()

	const loading = useAppSelector(state => state.appState.loading)
	const exercise = useAppSelector(state => state.newOrEditedExercise.exercise)
	const questions = useAppSelector(state => state.newOrEditedExercise.questions)
	const user = useAppSelector(state => state.user.data)
	const previousPage = useAppSelector(state => state.previousPage.path)
	const [copyTitle, setCopyTitle] = useState(exercise.title.text + ` (${t('preview_exercise.copy_exercise.title')})`)

	useEffect(() => {
		if (id && previousPage === '/') {
			dispatch(changePreviousPage('/edit_exercises'))
		}
	}, [id])

	/**
     * Callback for fetching and dispatching action wiht exercise data
     */
	const fetchExercise = useCallback(async () => {
		if (exercise.id === '') {
			dispatch(changeAppLoading(true))
			const data = await exerciseService.getById(id!)
			setCopyTitle(data.title.text + ` (${t('preview_exercise.copy_exercise.title')})`)
			dispatch(setEditExercise(data))
			dispatch(changeAppLoading(false))
		}
	}, [id])

	/**
     * Call the callback for fetching and storing the exercise data
    */
	useEffect(() => {
		try {
			fetchExercise()
		} catch (exception) {
			console.error(exception)
		}
	}, [fetchExercise])

	/**
	 * Function that is called from the delete exercise modal when it is closed
	 */
	const handleCloseDeleteExerciseModal = () => {
		setShowDeleteExerciseModal(false)
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
	}

	/**
	 * Function that is called from the delete exercise modal, when an exercise is deleted
	 */
	const handleDeleteExerciseModal = () => {
		setShowDeleteExerciseModal(false)
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
		navigate(previousPage === '/' ? '/edit_exercises' : previousPage)
	}

	/**
	 * Function that is called from the copy exercise modal when it is closed
	 */
	const handleCloseCopyExerciseModal = () => {
		setShowCopyExerciseModal(false)
		setCopyTitle(exercise.title.text + ` (${t('preview_exercise.copy_exercise.title')})`)
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
	}

	/**
	 * Function that is called from the delete exercise model when it is closed
	 */
	const openEditExerciseView = () => {
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
		navigate('/edit')
	}

	/**
	 * Function to create a copy of the previewed exercise
	 */
	const handleCopy = async () => {
		try {
			handleCloseCopyExerciseModal()
			dispatch(changeAppLoading(true))
			const formData = new FormData()
			const template = exercise.template
			formData.append('user', user.id)
			formData.append('title', copyTitle)
			formData.append('slpDescription', exercise.description.slp.text)
			formData.append('patientDescription', exercise.description.patient.text)
			formData.append('type', renderType!)

			Object.values(questions).forEach((q, i) => {
				if (template === EXERCISE_TEMPLATE_FILL) {
					if (q.start) formData.append('start', q.start)
					else formData.append('start', 'undefined')
					if (q.end) formData.append('end', q.end)
					else formData.append('end', 'undefined')
				} else {
					if (q.question.text) formData.append('question', q.question.text)
					else formData.append('question', 'undefined')
				}
				const answers = [...q.answers].map(answerObj => answerObj.answer.text.trim())
				formData.append('answers', JSON.stringify(answers))
				if (q.imageURL) {
					formData.append('imageURLs', q.imageURL)
				} else {
					formData.append('imageURLs', 'undefined')
				}
			})

			exercise.skill.forEach(t => {
				formData.append('skill', t)
			})
			exercise.subtype.forEach(t => {
				formData.append('subtype', t)
			})
			if (exercise.tags) {
				exercise.tags.forEach(t => {
					formData.append('tags', t)
				})
			}

			const answerFormat = exercise.template === 'comprehension' ? exercise.answerFormat! : exercise.template // in case we have old production exercises that lack answerFormat
			formData.append('answerFormat', answerFormat)
			const contentFormat = exercise.template === 'comprehension' ? 'default' : exercise.template
			formData.append('contentFormat', contentFormat)

			formData.append('isPublic', 'false')
			formData.append('hasImage', exercise.settings.hasImage.toString())
			formData.append('useHint', exercise.settings.useHint.toString())
			formData.append('shuffleQuestions', exercise.settings.shuffleQuestions.toString())
			formData.append('langCode', exercise.langCode)
			formData.append('template', template)
			formData.append('category', exercise.category)
			formData.append('isAdult', exercise.isAdult.toString())
			const difficulty = exercise.difficulty
			formData.append('difficulty', difficulty.toString())

			const userData = new FormData()
			userData.append('user', user.id)

			const res = await exerciseService.create(formData)
			if (res && res.id) {
				dispatch(addUserExerciseId(res.id))
			}

			dispatch(reset())
			const data = await exerciseService.getById(res.id)
			dispatch(setEditExercise(data))
			dispatch(changeAppLoading(false))
			navigate(`/exercises/view/${res.id}/${renderType}/false`)

			res.questions.forEach(rawId => {
				const id = rawId.toString()
				dispatch(updateAudio({ id, userData }))
			})
		} catch (err) {
			console.error('EXCEPTION OCCURED', err)
			dispatch(changeAppLoading(false))
		}
	}

	return (
		<Container className='user-select'>
			{loading
				? <Loading />
				: <>
					<Modal
						show={showCopyExerciseModal}
						onHide={handleCloseCopyExerciseModal}
						data-cy='copy_exercise_modal'
					>
						<Modal.Header closeButton>
							<Modal.Title>{t('preview_exercise.copy_exercise.modal.title')} "{exercise.title.text}"?</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<p>{t('preview_exercise.copy_exercise.modal.body')}</p>
							<Form>
								<Form.Control type='text' value={copyTitle} onChange={e => setCopyTitle(e.target.value)} autoFocus data-cy='copy_exercise_modal_input' />
							</Form>
							<Modal.Footer>
								<Button onClick={handleCloseCopyExerciseModal} variant='danger' data-cy='copy_exercise_modal_button_cancel'>{t('preview_exercise.copy_exercise.modal.cancel_button')}</Button>
								<Button onClick={handleCopy}>{t('preview_exercise.copy_exercise.modal.confirm_button')}</Button>
							</Modal.Footer>
						</Modal.Body>
					</Modal>
					<h2>{t('preview_exercise.page.title')}</h2>
					<hr />
					<ExercisePreviewTable exercise={exercise as LibraryExercise} baselib={baselib ? baselib === 'true' : false} renderType={renderType!} />
					{/** Don't show edit and delete buttons if viewing exercise when assigning to patient */}
					<Button
						onClick={() => navigate(`/exercises/download/${id}/${renderType}/${baselib}`)}
						className="my-2"
						data-cy='download_page_button'
					>
						<FontAwesomeIcon className='me-2' icon={faCloudArrowDown} />
						{t('preview_exercise.download_exercise.download_button')}
					</Button>
					<Button
						onClick={() => setShowCopyExerciseModal(true)}
						className="my-2 ms-4"
						data-cy='copy_exercise_button'
					>
						<FontAwesomeIcon className='me-2' icon={faCopy} />
						{t('preview_exercise.copy_exercise.button')}
					</Button>
					{ previousPage === '/edit_exercises' && (exercise.owner === user.id || user.type === 'admin')
						? <>
							<Button
								onClick={() => openEditExerciseView()}
								data-cy='edit_exercise_btn'
								className="my-2 ms-4"
							>
								<FontAwesomeIcon className='me-2' icon={faEdit} />
								{t('preview_exercise.page.edit_button')}
							</Button>
							<Button
								onClick={() => setShowDeleteExerciseModal(true)}
								data-cy='delete_exercise_btn'
								className="my-2 mx-4"
								variant="danger"
							>
								<FontAwesomeIcon className='me-2' icon={faTrashCan} />
								{t('preview_exercise.delete.delete_button')}
							</Button>
						</>
						: <></>
					}
					<h2 className='mt-3'>{t('preview_exercise.description.title')}</h2>
					<Row className='mb-3'>
						<Col className='m-2 p-2' style={{backgroundColor: 'rgba(0, 0, 0, 0.05)'}}>
							<h5>{t('preview_exercise.description.slp')}</h5>
							<p>{exercise.description.slp.text}</p>
						</Col>
						<Col className='m-2 p-2' style={{backgroundColor: 'rgba(0, 0, 0, 0.05)'}}>
							<h5>{t('preview_exercise.description.patient')}</h5>
							<p>{exercise.description.patient.text}</p>
						</Col>
					</Row>

					<h2>{t('preview_exercise.page.additional_info')}</h2>
					<Row className='mb-3' data-cy='exercise_preview_additional_info_row'>
						<Col className='m-2 p-2' data-cy='exercise_preview_additional_info_col_1' style={{backgroundColor: 'rgba(0, 0, 0, 0.05)'}}>
							<p className='mb-1'>
								{`${t('create_exercise.settings.langCode.label')}: `}
								<b>
									{t(`create_exercise.settings.langCode.${exercise.langCode}`)}
								</b>
							</p>
							<p>{`${t('edit_patient.exercises.table.number')}: `}
								<b>
									{exercise.questions.length}
								</b>
							</p>
						</Col>
						<Col className='m-2 p-2' data-cy='exercise_preview_additional_info_col_2' style={{backgroundColor: 'rgba(0, 0, 0, 0.05)'}}>
							<p className='mb-1'>
								{t('create_exercise.settings.question_settings.shuffle_questions_label')}
								<b>
									{exercise.settings.shuffleQuestions
										? `: ${t('edit_patient.exercises.table.yes')}`
										: `: ${t('edit_patient.exercises.table.no')}`}
								</b>
							</p>
							<p className='mb-1'>
								{t('create_exercise.settings.question_settings.using_images_label')}
								<b>
									{exercise.settings.hasImage
										? `: ${t('edit_patient.exercises.table.yes')}`
										: `: ${t('edit_patient.exercises.table.no')}`}
								</b>
							</p>
							<p className='mb-1'>
								{t('create_exercise.settings.question_settings.using_hints_label')}
								<b>
									{exercise.settings.useHint
										? `: ${t('edit_patient.exercises.table.yes')}`
										: `: ${t('edit_patient.exercises.table.no')}`}
								</b>
							</p>
						</Col>
					</Row>

					<h2>{t('preview_exercise.page.questions')}</h2>
					{exercise.id && (
						exercise.template !== 'comprehension' ?
							<QuestionsTable questions={exercise.questions}/>
							: <ComprehensionQuestionsTable />
					)}
					{showDeleteExerciseModal
						? <ConfirmDeleteExerciseModal
							handleClose={handleCloseDeleteExerciseModal}
							handleDelete={handleDeleteExerciseModal}
						/>
						: <></>
					}
				</>
			}
		</Container>
	)
}

export default ExercisePreviewPage