import React,{ useEffect, useState } from 'react'
import { Form, Row, Col, Accordion, Alert} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import {useDispatch } from 'react-redux'
import {useAppSelector } from 'stateHandling/hooks'
import {
	EXERCISE_TYPES,
	EXERCISE_SUBTYPES,
	EXERCISE_SUBTYPES_WRITE,
	EXERCISE_SUBTYPES_LISTEN_READ,
	EXERCISE_ANSWERFORMAT_COMPREHENSION,
	EXERCISE_TEMPLATES,
	EXERCISE_TEMPLATE_DEFAULT,
	EXERCISE_TEMPLATE_FILL,
	EXERCISE_SUBTYPE_AUTO,
	EXERCISE_TYPE_SPEAK,
	EXERCISE_TYPE_WRITE,
	EXERCISE_CATEGORIES,
	EXERCISE_TYPE_LISTEN,
	EXERCISE_TYPE_READ,
	EXERCISE_TEMPLATE_COMPREHENSION,
	EXERCISE_CONTENT_FORMATS,
	EXERCISE_SKILLS,
	SKILL_PHONOLOGY,
	SKILL_GRAMMAR,
	SKILL_UNDERSTANDING,
	SKILL_VOCABULARY,
	SKILL_PRAGMATICS,
	EXERCISE_SUBTYPES_CHILDREN_PHONOLOGY,
	EXERCISE_SUBTYPES_CHILDREN_GRAMMAR,
	EXERCISE_SUBTYPES_CHILDREN_UNDERSTANDING,
	EXERCISE_SUBTYPES_CHILDREN_VOCABULARY,
	EXERCISE_SUBTYPES_CHILDREN_PRAGMATICS
} from 'utils/config'

import {
	changePracticeUserCategory,
	changeExerciseType,
	changeExerciseSkill,
	changeExerciseAnswerFormat,
	changeExerciseSubtype,
	changeExerciseCategory,
	changeExerciseTemplate,
	changeNrOfQ,
	changeNrOfComprehensionQ,
	setYesNoAlt,
	changeHasImage,
	changeUsingHint,
	changeIsPublic,
	changeShuffleQuestions,
	reset
} from 'reducers/newOrEditedExerciseSlice'

interface CheckBoxFormat {
	type: string
	selected: boolean
	disabled: boolean
  }

interface Props {
	isEdit: boolean
}

const SelectType = ({ isEdit}:Props) => {
	const { t } = useTranslation()
	const exercise = useAppSelector(state => state.newOrEditedExercise.exercise)
	const nrOfQ = useAppSelector(state => state.newOrEditedExercise.nrOfQ)
	const defaultPracticeUserCategory = exercise.isAdult ? 'adult' : 'child'

	const dispatch = useDispatch()

	const [showSkills, setShowSkills] = useState(true)
	const [showSelectSubtype, setShowSelectSubtype] = useState(false)
	const [showSelectTemplate, setShowSelectTemplate] = useState(false)
	const [showSelectAnswerFormat, setShowSelectAnswerFormat] = useState(false)
	const [showAlert, setShowAlert] = useState(false)
	const [disablePracticeUserToggle, setDisablePracticeUserToggle] = useState(false)

	const [types, setTypes] = useState(EXERCISE_TYPES.map(t => {
		const typeObj = { type: t, selected: false, disabled: false }
		return typeObj
	}))

	const [skills, setSkills] = useState(EXERCISE_SKILLS.map(t => {
		const typeObj = { type: t, selected: false, disabled: false }
		return typeObj
	}))

	const [subtypes, setSubtypes] = useState(EXERCISE_SUBTYPES.map(t => {
		return { type: t, selected: false, disabled: false }
	}))

	// Childrens library subtypes 
	const flatten = (arr) => arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? flatten(val) : val), [])

	const [subtypesChildrenPhonology, setSubtypesChildrenPhonology] = useState(flatten(EXERCISE_SUBTYPES_CHILDREN_PHONOLOGY).map(t => {
		return { type: t, selected: false, disabled: false }
	}))
	const [subtypesChildrenGrammar, setSubtypesChildrenGrammar] = useState(flatten(EXERCISE_SUBTYPES_CHILDREN_GRAMMAR).map(t => {
		return { type: t, selected: false, disabled: false }
	}))
	const [subtypesChildrenUnderstanding, setSubtypesChildrenUnderstanding] = useState(flatten(EXERCISE_SUBTYPES_CHILDREN_UNDERSTANDING).map(t => {
		return { type: t, selected: false, disabled: false }
	}))
	const [subtypesChildrenVocabulary, setSubtypesChildrenVocabulary] = useState(flatten(EXERCISE_SUBTYPES_CHILDREN_VOCABULARY).map(t => {
		return { type: t, selected: false, disabled: false }
	}))
	const [subtypesChildrenPragmatics, setSubtypesChildrenPragmatics] = useState(flatten(EXERCISE_SUBTYPES_CHILDREN_PRAGMATICS).map(t => {
		return { type: t, selected: false, disabled: false }
	}))

	const [categories, setCategories] = useState(EXERCISE_CATEGORIES)
	const [templates, setTemplates] = useState(EXERCISE_TEMPLATES)
	const [contentFormats, setContentFormats] = useState(EXERCISE_CONTENT_FORMATS) // for future

	const [answerFormat, setAnswerFormat] = useState(EXERCISE_ANSWERFORMAT_COMPREHENSION.map(t => {
		return { type: t, selected: false, disabled: false }
	}))

	const [borderColor, setBorderColor] = useState('')

	useEffect(() => {
		const initialAnswerFormats = EXERCISE_ANSWERFORMAT_COMPREHENSION.map(t => {
			const typeObj = { type: t, selected: false, disabled: false }

			if (typeObj.type === exercise.answerFormat) {
				typeObj.selected = true
			}

			return typeObj
		})
		setAnswerFormat(initialAnswerFormats)
	}, [exercise.answerFormat])

	/**
     * Responsible for updating border color of the Accordion depending on if information is filled in
     */
	useEffect(() => {
		const hasType = exercise.type.length > 0
		const hasSubtype = exercise.subtype.length > 0
		const hasSkill = exercise.skill.length > 0
		const isUnderstanding = hasSkill && exercise.skill[0] === 'understanding'
		const isComprehension = exercise.template === 'comprehension'
		const hasAnswerFormat = exercise.answerFormat !== undefined

		let isValid = false

		if (isComprehension) {
			if (exercise.isAdult) {
				// Adult and comprehension
				isValid = isUnderstanding ? (hasType && hasAnswerFormat) : (hasType && hasAnswerFormat && hasSubtype)
			} else {
				// Not adult and comprehension
				isValid = isUnderstanding ? (hasType && hasAnswerFormat && hasSkill) : (hasType && hasAnswerFormat && hasSkill && hasSubtype)
			}
		} else {
			if (exercise.isAdult) {
				// Adult and not comprehension
				isValid = isUnderstanding ? hasType : (hasType && hasSubtype)
			} else {
				// Not adult and not comprehension
				isValid = isUnderstanding ? (hasType && hasSkill) : (hasType && hasSkill && hasSubtype)
			}
		}

		setBorderColor(isValid ? '' : 'red')
	}, [exercise])

	/**
     * Setting exercise type, subtype and props when editing exercise
     */
	useEffect(() => {
		if (isEdit) {
			setDisablePracticeUserToggle(true)

			const tempTypes = types.map(item => {
				if (exercise.type.includes(item.type)) {
					item.selected = true
				}
				return item
			})
			setTypes(tempTypes)

			let tempSubtypes: CheckBoxFormat[] = []

			if (!exercise.isAdult){
				const tempSkills = skills.map(item => {
					if (exercise.skill.includes(item.type)) {
						item.selected = true
					}
					return item
				})
				setSkills(tempSkills)

				let tempChildSubtypes: CheckBoxFormat[] =[]
				if (exercise.skill.includes(SKILL_PHONOLOGY)){
					tempChildSubtypes = subtypesChildrenPhonology
				}
				else if (exercise.skill.includes(SKILL_GRAMMAR)){
					tempChildSubtypes = subtypesChildrenGrammar
				}
				else if (exercise.skill.includes(SKILL_UNDERSTANDING)){
					tempChildSubtypes = subtypesChildrenUnderstanding
				}
				else if (exercise.skill.includes(SKILL_VOCABULARY)){
					tempChildSubtypes = subtypesChildrenVocabulary
				}
				else if (exercise.skill.includes(SKILL_PRAGMATICS)){
					tempChildSubtypes = subtypesChildrenPragmatics
				}

				tempSubtypes = tempChildSubtypes.map(item => {
					if (exercise.subtype.includes(item.type)) {
						item.selected = true
					}
					return item
				})
			}
			else {
				setShowSkills(false)
				tempSubtypes = subtypes.map(item => {
					if (exercise.subtype.includes(item.type)) {
						item.selected = true
						// Cannot set a write type exercise to have other than write subtype
					} if (tempTypes.filter(t => t.selected).map(t => t.type).includes(EXERCISE_TYPE_WRITE)
							&& !EXERCISE_SUBTYPES_WRITE.includes(item.type)) {
						item.disabled = true
						// Cannot set a fill exercise to auto-subtype
					} if (exercise.template === EXERCISE_TEMPLATE_FILL
							&& item.type === EXERCISE_SUBTYPE_AUTO) {
						item.disabled = true
					}
					return item
				})
			}

			if (exercise.template === 'comprehension'){
				setShowSelectAnswerFormat(true)
			}

			setSubtypes(tempSubtypes)
			setTemplates([exercise.template])
			setShowSelectSubtype(true)
			setShowSelectTemplate(true)
			setShowAlert(true)
		}
	}, [])

	// Function to get a type-object with the initial settings
	const getInitialTypeObject = () => {
		const formattedTypes = EXERCISE_TYPES.map(t => {
			const typeObj = { type: t, selected: false, disabled: false }
			return typeObj
		})
		return formattedTypes
	}

	// Function to get a skills-object with the initial settings
	const getInitialSkillsObject = () => {
		const formattedTypes = EXERCISE_SKILLS.map(t => {
			const typeObj = { type: t, selected: false, disabled: false }
			return typeObj
		})
		return formattedTypes
	}

	// Event handler for when changing the value for number of questions
	const handleNrOfQ = event => {
		event.preventDefault()
		if (exercise.template === 'comprehension') {
			dispatch(changeNrOfComprehensionQ(Number(event.target.value)))
			if (exercise.answerFormat === 'yes/no') dispatch(setYesNoAlt())
		} else {
			dispatch(changeNrOfQ(Number(event.target.value)))
		}
	}

	// Event handler for when changing the value of practice user category
	const handleExercisePracticeUserCategory = (event) => {
		event.preventDefault()
		const selectedPracticeUserCategory = event.target.value
		dispatch(reset())
		dispatch(changePracticeUserCategory(selectedPracticeUserCategory))
		const tempTypes = clearTypeSelection()
		const tempSkills = clearSkillSelection()
		const tempSubtypes = clearSubtypeSelection()
		setTypes(tempTypes)
		setSkills(tempSkills)
		setSubtypes(tempSubtypes)
		setShowSkills(!showSkills)
		setShowSelectSubtype(false)
		setShowSelectAnswerFormat(false)
		setShowSelectTemplate(false)
	}

	// Event handler for when changing the value for category
	const handleCategory = event => {
		event.preventDefault()
		dispatch(changeExerciseCategory(event.target.value))
	}

	// Event handler for when changing the template to use
	const handleTemplateSelect = event => {
		event.preventDefault()
		dispatch(changeExerciseTemplate(event.target.value))
	}

	// Event handler for when checking/unchecking using image
	const handleUseImage = () => {
		dispatch(changeHasImage())
	}

	// Event handler for when checking/unchecking is public property
	const handleIsPublic = () => {
		dispatch(changeIsPublic())
	}

	// Event handler for when checking/unchecking using hint
	const handleUsingHint = () => {
		dispatch(changeUsingHint())
	}

	// Event handler for when checking/unchecking shuffling questions
	const handleShuffleQuestions = () => {
		dispatch(changeShuffleQuestions())
	}

	// Helper function to clear all data in subtypes col
	const clearSubtypeSelection = () => {
		const tempSubtypes = subtypes.map(item => {
			item.selected = false
			item.disabled = false
			return item
		})
		return tempSubtypes
	}

	// Helper function to clear all data in skills col
	const clearSkillSelection = () => {
		const tempSkills = skills.map(item => {
			item.selected = false
			item.disabled = false
			return item
		})
		return tempSkills
	}

	// Helper function to clear all data in types col, when toggling between child or adult
	const clearTypeSelection = () => {
		const tempTypes = types.map(item => {
			item.selected = false
			item.disabled = false
			return item
		})

		return tempTypes
	}

	/**
     * Helper function that transform an array of strings (exercise types or subtypes, answerformat)
     * To the format that are used when redering the checkboxes
     * @param {Event} event - event intance for onKeyDown event
     */
	const formatTypeAlternatives = typeArr => {
		const tempSubtypes = typeArr.map(t => {
			return { type: t, selected: false, disabled: false }
		})
		return tempSubtypes
	}

	/**
     * Event handler for checking a box in the skills col
     * @param {Event} event - event intance for onKeyDown event
     */
	const onSkillsCheck = (event) => {
		const selectedSkill = event.target.value
		let tempSkills = skills.map(item => {
			if (item.type === selectedSkill) {
				item.selected = event.target.checked
			}
			else {
				item.disabled = true
			}
			return item
		})

		const selected = skills.filter(item => item.selected).map(item => item.type)

		if (selected.length === 0) {
			tempSkills = getInitialSkillsObject()
			const tempSubtypes = clearSubtypeSelection()
			setSubtypes(tempSubtypes)
			setShowSelectSubtype(false)
		}
		else {
			if (selected.includes(SKILL_PHONOLOGY)){
				setSubtypes(subtypesChildrenPhonology)
				setShowSelectSubtype(true)
			}
			else if (selected.includes(SKILL_GRAMMAR)){
				setSubtypes(subtypesChildrenGrammar)
				setShowSelectSubtype(true)
			}
			else if (selected.includes(SKILL_UNDERSTANDING)){
				setSubtypes(subtypesChildrenUnderstanding)

				// Add back if we add any subtypes to understanding in the future
				// setShowSelectSubtype(true)
			}
			else if (selected.includes(SKILL_VOCABULARY)){
				setSubtypes(subtypesChildrenVocabulary)
				setShowSelectSubtype(true)
			}
			else if (selected.includes(SKILL_PRAGMATICS)){
				setSubtypes(subtypesChildrenPragmatics)
				setShowSelectSubtype(true)
			}
		}

		setSkills(tempSkills)
		dispatch(changeExerciseSkill(selected))
	}

	/**
     * Event handler for checking a box in the types col
     * @param {Event} event - event intance for onKeyDown event
     */
	const onTypeCheck = (event) => {
		const selectedType = event.target.value

		let tempTypes = types.map(item => {
			if (item.type === selectedType) {
				item.selected = event.target.checked
			} else {
				item.disabled = true
			}
			return item
		})

		const selected = types.filter(item => item.selected).map(item => item.type)

		if (selected.length === 0) {
			tempTypes = getInitialTypeObject()

			if (exercise.isAdult){
				const tempSubtypes = clearSubtypeSelection()
				setSubtypes(tempSubtypes)
				setShowSelectSubtype(false)
			}

			setShowSelectTemplate(false)
			setShowSelectAnswerFormat(false)
			//Perhaps not the wanted behavior? Fixed issue with comprehension-template remaining as template even when unchecking the boxes for read and listen.
			dispatch(changeExerciseTemplate('default'))
		}

		else {
			if (selected.includes(EXERCISE_TYPE_SPEAK) || selected.includes(EXERCISE_TYPE_WRITE)) {
				setShowSelectTemplate(true)
				setShowSelectAnswerFormat(false)
			}
			if (selected.includes(EXERCISE_TYPE_WRITE)) {
				if (exercise.isAdult) {
					setSubtypes(formatTypeAlternatives(EXERCISE_SUBTYPES_WRITE))
				}
			} else {
				if (exercise.isAdult) {
					setSubtypes(formatTypeAlternatives(EXERCISE_SUBTYPES))
				}
			}
			if (selected.includes(EXERCISE_TYPE_LISTEN) || selected.includes(EXERCISE_TYPE_READ)) {
				setTemplates([EXERCISE_TEMPLATE_COMPREHENSION])
				if (exercise.isAdult) {
					setSubtypes(formatTypeAlternatives(EXERCISE_SUBTYPES_LISTEN_READ))
				}
				setShowSelectAnswerFormat(true)
				setShowSelectTemplate(true)
				//Since it's the only option, the onchange never triggers. So that's why I'm changing the state here.
				dispatch(changeExerciseTemplate('comprehension'))
			} else {
				setTemplates([EXERCISE_TEMPLATE_DEFAULT, EXERCISE_TEMPLATE_FILL])
			}
		}
		if (exercise.isAdult){
			setShowSelectSubtype(true)
		}
		setTypes(tempTypes)
		dispatch(changeExerciseType(selected))
	}

	/**
     * Event handler for checking a box in the subtypes col
     * @param {Event} event - event intance for onKeyDown event
     */
	const onSubtypeCheck = (event) => {
		const selectedType = event.target.value
		const tempTypes = subtypes.map(item => {
			if (item.type === selectedType) {
				item.selected = event.target.checked
			}
			return item
		})
		setSubtypes(tempTypes)

		const selected = tempTypes.filter(item => item.selected).map(item => item.type)

		dispatch(changeExerciseSubtype(selected))
	}
	/**
     * Event handler for checking a box in the answerformat col
     * @param {Event} event - event intance for onKeyDown event
     */
	const onAnswerFormatCheck = (event) => {
		const selectedAnswerFormat = event.target.value
		const updatedAnswerFormats = answerFormat.map(item => ({
			...item,
			selected: item.type === selectedAnswerFormat,
		}))
		setAnswerFormat(updatedAnswerFormats)
		dispatch(changeExerciseAnswerFormat(selectedAnswerFormat))
	}

	return (
		<Accordion defaultActiveKey="0">
			<Accordion.Item eventKey="0" style={{borderColor: borderColor}}>
				<Accordion.Header>
					<Form.Label className="fw-bold">{t('create_exercise.settings.title')}</Form.Label>
				</Accordion.Header>
				<Accordion.Body>
					{showAlert ? <Alert variant='warning'>{t('edit_exercise.alert')}</Alert> : null }
					<Row className='mb-2'>
						<Form.Group className="mb-3" controlId='exercisePracticeUserCategory'>
							<Form.Label className="fw-bold">{t('create_exercise.settings.title')}</Form.Label>
							<Form.Select
								name="exercisePracticeUserCategory"
								disabled={disablePracticeUserToggle}
								className='w-50'
								onChange={handleExercisePracticeUserCategory}
								value={defaultPracticeUserCategory}
								data-cy="exercise_practice_user_category"
							>
								<option value="child">{t('create_exercise.settings.practice_user_category.child')}</option>
								<option value="adult">{t('create_exercise.settings.practice_user_category.adult')}</option>
							</Form.Select>
						</Form.Group>
						<Col>
							<Form.Label className="fw-bold">{t('create_exercise.settings.types.label')}</Form.Label>
							{(types.map(item => (
								<Form.Check
									key={item.type}
									onChange={(event) => onTypeCheck(event)}
									checked={item.selected}
									type='checkbox'
									value={item.type}
									label={t(`create_exercise.settings.types.${item.type}`)}
									disabled={item.disabled || isEdit}
								/>
							))
							)}
						</Col>
						{showSkills ?
							<Col>
								<Form.Label className="fw-bold" data-cy="exercise_skill_label">{t('create_exercise.settings.skill_title')}</Form.Label>
								{skills.map(item => (
									<Form.Check
										key={item.type}
										onChange={(event) => onSkillsCheck(event)}
										checked={item.selected}
										type='checkbox'
										value={item.type}
										label={t(`create_exercise.settings.skills.${item.type}`)}
										disabled={item.disabled || isEdit}
									/>
								))
								}
							</Col>: <></>
						}
						<Col>
							{showSelectSubtype &&
                                (<>
                                	<Form.Label className="fw-bold" data-cy="exercise_subtype_label">{t('create_exercise.settings.subtypes.label')}</Form.Label>
                                	{subtypes.map(item => {
                                		return (
                                			<Form.Check
                                				key={item.type}
                                				onChange={(event) => onSubtypeCheck(event)}
                                				checked={item.selected}
                                				type='checkbox'
                                				value={item.type}
                                				label={t(`create_exercise.settings.subtypes.${item.type}`)}
                                				disabled={item.disabled}
                                			/>
                                		)})
                                	}
                                </>)}
						</Col>
						<Col>
							{showSelectTemplate ?
								<>
									<Form.Label className="fw-bold">{t('create_exercise.settings.templates.label')}</Form.Label>
									<Form.Select onChange={handleTemplateSelect} disabled={isEdit} data-cy="select_question_format">
										{templates
											.map((template) => (
												<option key={template} value={template}>
													{t(`create_exercise.settings.templates.${template}`)}
												</option>
											))}
									</Form.Select>
								</>
								: <></>
							}
							{showSelectAnswerFormat &&
                                (<>
                                	<Form.Label className="fw-bold" data-cy="answer_format_label" >{t('create_exercise.settings.answer_format.label')}</Form.Label>
                                	{answerFormat.map(item => {
                                		return (
                                			<Form.Check
                                				key={item.type}
                                				onChange={(event) => onAnswerFormatCheck(event)}
                                				checked={item.selected}
                                				type='checkbox'
                                				value={item.type}
                                				data-cy={item.type}
                                				label={t(`create_exercise.settings.answer_format.${item.type}`)}
                                				disabled={item.disabled || isEdit}
                                			/>
                                		)})
                                	}
                                </>)}
						</Col>
					</Row>
					<Row className='mb-2'>
						<Col>
							<Form.Label className="fw-bold">{t('create_exercise.settings.select_nr_questions.label')}</Form.Label>
							<Form.Select data-cy="exercise_number_questions" className='w-50' onChange={handleNrOfQ} value={nrOfQ} disabled={isEdit}>
								<option value={5}>{t('create_exercise.settings.select_nr_questions.5')}</option>
								<option value={6}>{t('create_exercise.settings.select_nr_questions.6')}</option>
								<option value={7}>{t('create_exercise.settings.select_nr_questions.7')}</option>
								<option value={8}>{t('create_exercise.settings.select_nr_questions.8')}</option>
								<option value={9}>{t('create_exercise.settings.select_nr_questions.9')}</option>
								<option value={10}>{t('create_exercise.settings.select_nr_questions.10')}</option>
								<option value={12}>{t('create_exercise.settings.select_nr_questions.12')}</option>
								<option value={15}>{t('create_exercise.settings.select_nr_questions.15')}</option>
								<option value={25}>{t('create_exercise.settings.select_nr_questions.25')}</option>
							</Form.Select>
						</Col>
						<Col>
							<Form.Label className="fw-bold">{t('create_exercise.settings.category.label')}</Form.Label>
							<Form.Select data-cy="exercise_category" className='w-50' onChange={handleCategory} value={exercise.category}>
								{categories.map(d => {
									return (
										<option key={d} value={d}>
											{t(`create_exercise.settings.category.${d}`)}
										</option>)
								})
								}
							</Form.Select>
						</Col>
						<Col sm={4}>
							<Form.Label className="fw-bold">{t('create_exercise.settings.question_settings.label')}</Form.Label>
							<Form.Group data-cy="exercise_is_public" as={Col}>
								<Form.Check
									onChange={handleIsPublic}
									checked={exercise.settings.isPublic}
									type='checkbox'
									id='is-public'
									label={t('create_exercise.settings.question_settings.is_public_label')}
								/>
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Check
									onChange={handleShuffleQuestions}
									checked={exercise.settings.shuffleQuestions}
									type='checkbox'
									id='shuffle-questions'
									label={t('create_exercise.settings.question_settings.shuffle_questions_label')}
								/>
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Check
									data-cy={'include_image_checkbox'}
									onChange={handleUseImage}
									checked={exercise.settings.hasImage}
									type='checkbox'
									id='include-image'
									label={t('create_exercise.settings.question_settings.using_images_label')}
								/>
							</Form.Group>
							{!showSelectAnswerFormat ?
								<Form.Group as={Col}>
									<Form.Check
										onChange={handleUsingHint}
										checked={exercise.settings.useHint}
										type='checkbox'
										id='useHint'
										label={t('create_exercise.settings.question_settings.using_hints_label')}
									/>
								</Form.Group>
								: <></>}
						</Col>
					</Row>
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	)
}

export default SelectType